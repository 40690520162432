
var newsFeedList = document.querySelector(".news-feed-list-js");

if (newsFeedList) {
  var newsListOnload = function newsListOnload() {
    hideAllNews(newsFeedListItems);
    showCurrentNewsList(newsFeedListItems, currentPageNum);
    creatPagination(currentPageNum, maxPugCounter);
    setActiveButtons(currentPageNum);
  };

  var hideAllNews = function hideAllNews(newsList) {
    newsList.map(function (item) {
      item.classList.add("visually-hidden");
      item.classList.remove("active-news");
    });
  };

  var showCurrentNewsList = function showCurrentNewsList(newsList, pageNum) {
    var endPageIndex = newsOnPageAmount * pageNum - 1;
    var startPageIndex = endPageIndex - newsOnPageAmount + 1;
    newsList.map(function (item, index) {
      if (index >= startPageIndex && index <= endPageIndex) {
        item.classList.remove("visually-hidden");
        item.classList.add('active-news');
        var itemArticle = item.querySelector(".news-feed-item-article-js");
        itemArticle.textContent = cutLongArticle(itemArticle.innerText);
      }
    });
    var activeNews = document.querySelectorAll('.active-news');
    Array.from(activeNews)[0].style.paddingTop = '0px';
    Array.from(activeNews)[newsOnPageAmount - 1].style.border = 'none'; // console.log(newsFeedList.style.height);
  };

  var cutLongArticle = function cutLongArticle(str) {
    if (str.length >= newsArticlemaxLength) {
      if (window.innerWidth > 480) {
        return str.substr(0, newsArticlemaxLength) + "...";
      } else {
        return str.substr(0, newsArticlemaxLengthOnMobile) + "...";
      }
    } else {
      return str;
    }
  };

  var creatPagination = function creatPagination(pageNum, Counter) {
    var pagContent = "";

    for (var i = pageNum; i < pageNum + Counter; i += 1) {
      pagContent += "<span class=\"news-feed__pagination-item\">".concat(i, "</span>");
    }

    newsFeedPaginationList.innerHTML = pagContent;
    creatPaginationlight(pageNum);
    Array.from(newsFeedPaginationList.children).forEach(function (item) {
      if (+item.textContent <= maxPagNum) {
        item.classList.add('link--hover-color-orange');
        item.addEventListener('click', function () {
          creatNewNewsList(+event.target.textContent);
        });
      }
    });
  };

  var creatPaginationlight = function creatPaginationlight(pageNum) {
    Array.from(newsFeedPaginationList.children).forEach(function (item) {
      if (item.textContent == pageNum) {
        item.classList.add("selected");
      } else {
        item.classList.remove("selected");
      }
    });
  };

  var creatNewNewsList = function creatNewNewsList(currentPageNum) {
    if (currentPageNum > +Array.from(newsFeedPaginationList.children)[9].textContent) {
      creatPagination(currentPageNum, maxPugCounter);
    }

    if (currentPageNum < +Array.from(newsFeedPaginationList.children)[0].textContent) {
      creatPagination(currentPageNum - 9, maxPugCounter);
    }

    hideAllNews(newsFeedListItems);
    showCurrentNewsList(newsFeedListItems, currentPageNum);
    creatPaginationlight(currentPageNum);
    setActiveButtons(currentPageNum);
  };

  var setActiveButtons = function setActiveButtons(currentPageNum) {
    // console.log(currentPageNum);
    if (currentPageNum <= 1) {
      backPagNews.classList.remove('hover-slider-buttons--big');
    } else {
      backPagNews.classList.add('hover-slider-buttons--big');
    }

    if (currentPageNum >= maxPagNum) {
      aheadPagNews.classList.remove('hover-slider-buttons--big');
    } else {
      aheadPagNews.classList.add('hover-slider-buttons--big');
    }
  };

  var currentElement = function currentElement() {
    var element;
    Array.from(newsFeedPaginationList.children).forEach(function (el) {
      if (el.classList.contains('selected')) {
        element = +el.textContent;
      }
    });
    return element;
  };

  var newsFeedListItems = Array.from(newsFeedList.children);
  var newsFeedPaginationList = document.querySelector(".news-feed-pagination-list-js");
  var aheadPagNews = document.querySelector('.ahead-pag-news-js');
  var backPagNews = document.querySelector('.back-pag-news-js ');
  var newsArticlemaxLength = 114;
  var newsArticlemaxLengthOnMobile = 95;
  var newsOnPageAmount = 8;
  var currentPageNum = 1;
  var maxPugCounter = 10;
  var maxPagNum = Math.ceil(newsFeedListItems.length / newsOnPageAmount); // console.log(maxPagNum)

  newsListOnload();
  aheadPagNews.addEventListener('click', function () {
    currentPageNum = currentElement() + 1;
    setActiveButtons(currentPageNum);

    if (currentPageNum > maxPagNum) {
      currentPageNum -= 1;
      setActiveButtons(currentPageNum);
      return;
    }

    creatNewNewsList(currentPageNum);
  });
  backPagNews.addEventListener('click', function () {
    currentPageNum = currentElement() - 1;
    setActiveButtons(currentPageNum);

    if (currentPageNum <= 0) {
      currentPageNum = 1;
      setActiveButtons(currentPageNum);
      return;
    }

    creatNewNewsList(currentPageNum);
  });
}