"use strict";

var supportHiddenMobileForm = document.querySelector('.support-hidden-mobile-form-js');

if (supportHiddenMobileForm) {
  var checkForHideOrShowButtomForm = function checkForHideOrShowButtomForm() {
    if (window.innerWidth <= 480) {
      var hideMobileFormBtn = document.querySelector('.hide-mobile-form-btn-js');
      hideMobileFormBtn.addEventListener('click', function () {
        supportHiddenMobileForm.style.display = 'flex';
        hideMobileFormBtn.style.display = 'none';
        setTimeout(function () {
          document.getElementById('hide-support').style.display = 'none';
        }, 10);
      });
    }
  };

  checkForHideOrShowButtomForm();
  window.addEventListener('resize', function () {
    checkForHideOrShowButtomForm();
  });
}