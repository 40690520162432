"use strict";

var customerCaseDescrBoxTitles = document.querySelectorAll('.customer-case-descr-box-title-js');

if (customerCaseDescrBoxTitles) {
  if (window.innerWidth < 768) {
    customerCaseDescrBoxTitles.forEach(function (title) {
      title.nextElementSibling.classList.remove('customer-descr-box-active');
    });
    customerCaseDescrBoxTitles.forEach(function (title) {
      title.addEventListener('click', function () {
        title.nextElementSibling.classList.toggle('customer-descr-box-active');
      });
    });
  }
}