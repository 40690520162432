"use strict";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var groupageCargoFromEuropeTabSlider = document.querySelector('.groupage-cargo-from-europe-tab-slider-js');
var tabsContentTopPanesList = document.querySelectorAll('.tabs-content-top-pane-js');
var tabsTopLinkSecondList = document.querySelectorAll('.tabs-top-link-second-js');
var promoTableGroupageCargoFromEurope = document.querySelector('.promo-table-groupage-cargo-from-europe-js');
var flagDescrListWrapper = document.querySelector('.flag-descr-list-wrapper-js');
var flagDescrListSecondWrapper = document.querySelector('.flag-descr-list-second-wrapper-js');
var dynamicRightContainerSlider = document.querySelector('.dynamic-right-container-slider-js'); /////service tab vars

var promoTableList = document.querySelector('.promo-table-list-js');
var promoTableItems = document.querySelectorAll('.promo-table-item-js'); //////// timetable tab vars

var flagDescrList = document.querySelector('.flag-descr-list-js');
var flagDescrListItems = document.querySelectorAll('.flag-descr-list-item-js'); /////////on hub tab vars 

var flagDescrSecondList = document.querySelector('.flag-descr-second-list-js');
var flagDescrListSecondItems = document.querySelectorAll('.flag-descr-list-second-item-js'); ////// second blocks slider 

var integratedLogisticsSliderGroupCargoList = document.querySelector('.integrated-logistics-slider-group-cargo-list-js');
var cardSecondBlockGroupCargoSliderItems = document.querySelectorAll('.card-second-block-groupcargo-slider-item'); // if(document.documentElement.clientWidth <=2000){    \
// if(window.innerWidth<=480){
//   createSwiperSlider(promoTableGroupageCargoFromEurope,promoTableList,promoTableItems);
//   createSwiperSlider(flagDescrListWrapper,flagDescrList,flagDescrListItems);
//   createSwiperSlider(flagDescrListSecondWrapper,flagDescrSecondList,flagDescrListSecondItems);   
// }
// if(window.innerWidth>480){
//   deleteSwiperSlider(promoTableGroupageCargoFromEurope,promoTableList,promoTableItems);
//   deleteSwiperSlider(flagDescrListWrapper,flagDescrList,flagDescrListItems);
//   deleteSwiperSlider(flagDescrListSecondWrapper,flagDescrSecondList,flagDescrListSecondItems);   
// }

if (promoTableGroupageCargoFromEurope) {
  createSwiperSlider(promoTableGroupageCargoFromEurope, promoTableList, promoTableItems);
}

if (flagDescrListWrapper) {
  createSwiperSlider(flagDescrListWrapper, flagDescrList, flagDescrListItems);
}

if (flagDescrListSecondWrapper) {
  createSwiperSlider(flagDescrListSecondWrapper, flagDescrSecondList, flagDescrListSecondItems);
}

if (dynamicRightContainerSlider) {
  createSwiperSlider(dynamicRightContainerSlider, integratedLogisticsSliderGroupCargoList, cardSecondBlockGroupCargoSliderItems);
}

function createSwiperSlider(container, list, itemsList) {
  container.classList.add('swiper-container');
  container.style.display = 'block';
  list.classList.add('swiper-wrapper');
  list.style.display = 'flex';
  list.style.flexDirection = 'row';
  list.style.flexWrap = 'nowrap';
  itemsList.forEach(function (item) {
    item.classList.add('swiper-slide');
    item.classList.add('swiper-no-swiping');
  });
}

function deleteSwiperSlider(container, list, itemsList) {
  container.classList.remove('swiper-container');
  list.classList.remove('swiper-wrapper');
  list.style.flexWrap = 'wrap';
  itemsList.forEach(function (item) {
    item.classList.remove('swiper-slide');
    item.classList.remove('swiper-no-swiping');
    item.style.width = '';
  });
}

var promoTableSwiper;
var promoFlagSwiper;
var promoFlagSecondSwiper;
var groupCargoSecondSlider;

if (dynamicRightContainerSlider) {
  groupCargoSecondSlider = new Swiper('.dynamic-right-container-slider-js', {
    init: false,
    breakpointsInverse: true,
    noSwiping: false,
    slidesPerView: 'auto',
    // slidesPerView: 4,
    // spaceBetween: 40,
    // centeredSlides: true,
    spaceBetween: 20,
    freeMode: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    // slidesPerView: 'auto',
    // centeredSlides: true,      
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    breakpoints: {
      320: {// slidesPerView: 2,
      },
      481: {
        // noSwiping:true,
        // slidesPerView: 1,  
        spaceBetween: 0,
        slidesPerView: 2
      },
      769: {
        centeredSlides: false,
        slidesPerView: 2,
        spaceBetween: 10
      }
    }
  });
  groupCargoSecondSlider.init();
}

if (document.documentElement.clientWidth <= 1024) {
  if (promoTableGroupageCargoFromEurope) {
    promoTableSwiper = new Swiper('.promo-table', {
      init: false,
      breakpointsInverse: true,
      noSwiping: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        320: {
          slidesPerView: 1
        },
        481: {// noSwiping: true,          
        },
        769: {
          slidesPerView: 2
        } // 1025:{
        //   noSwiping:true,
        // }

      }
    });
    promoTableSwiper.init();
  }

  if (flagDescrListWrapper) {
    promoFlagSwiper = new Swiper('.flag-descr-list-wrapper-js', {
      init: false,
      breakpointsInverse: true,
      noSwiping: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        320: {
          slidesPerView: 1
        },
        481: {// noSwiping: true,          
        },
        769: {
          slidesPerView: 2
        }
      }
    });
    promoFlagSwiper.init();
  }

  if (flagDescrListSecondWrapper) {
    promoFlagSecondSwiper = new Swiper('.flag-descr-list-second-wrapper-js', {
      init: false,
      breakpointsInverse: true,
      noSwiping: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        320: {
          slidesPerView: 1
        },
        481: {// noSwiping: true,          
        },
        769: {
          slidesPerView: 2
        }
      }
    });
    promoFlagSecondSwiper.init();
  }
}

if (document.documentElement.clientWidth > 1024) {
  if (promoTableGroupageCargoFromEurope) {
    deleteSwiperSlider(promoTableGroupageCargoFromEurope, promoTableList, promoTableItems);
  }

  if (flagDescrListWrapper) {
    deleteSwiperSlider(flagDescrListWrapper, flagDescrList, flagDescrListItems);
  }

  if (flagDescrListSecondWrapper) {
    deleteSwiperSlider(flagDescrListSecondWrapper, flagDescrSecondList, flagDescrListSecondItems);
  }
} // console.log(tabsContentTopPanesList.length);


if (tabsContentTopPanesList.length !== 0) {
  var hideAllpanes = function hideAllpanes() {
    tabsContentTopPanesList.forEach(function (pane) {
      // pane.classList.add('visually-hidden');
      pane.style.display = 'none';
    });
  };

  var tabsTopLinksList = document.querySelectorAll('.tabs-top-link-js');
  hideAllpanes();
  tabsContentTopPanesList[0].style.display = 'block';
  tabsTopLinksList.forEach(function (paneLink) {
    paneLink.addEventListener('click', function () {
      var target = event.target;
      tabsTopLinksList.forEach(function (pane) {
        pane.classList.remove('active-tabs-top-link');
      });
      target.classList.add('active-tabs-top-link');
      var textId = target.getAttribute("id").slice(3);
      var currentPane = document.getElementById(textId);
      hideAllpanes();
      currentPane.style.display = 'block';
    });
  });
}

if (tabsTopLinkSecondList.length !== 0) {
  console.log(tabsTopLinkSecondList);
  var dynamicLeftContaines = document.querySelectorAll('.dynamic-left-container-js');
  var dynamicRightContainers = document.querySelectorAll('.dynamic-right-container-js ');
  dynamicRightContainers.forEach(function (container) {
    container.classList.remove('tabs-content__top-pane--active');
  });
  dynamicRightContainers[0].classList.add('tabs-content__top-pane--active');
  tabsTopLinkSecondList.forEach(function (link) {
    link.addEventListener('click', function () {
      var target = event.target;
      var textId = target.getAttribute('id').slice(1);
      tabsTopLinkSecondList.forEach(function (elementLink) {
        elementLink.classList.remove('active-tabs-top-link--second');
      });
      target.classList.add('active-tabs-top-link--second'); // console.log(textId);

      dynamicLeftContaines.forEach(function (container) {
        dinamycContainerSwitcher(container);
      });
      dynamicRightContainers.forEach(function (container) {
        dinamycContainerSwitcher(container);
      });

      function dinamycContainerSwitcher(container) {
        container.classList.remove('tabs-content__top-pane--active'); // console.log(container);

        if (container.getAttribute('id').slice(3) == textId) {
          container.classList.add('tabs-content__top-pane--active');
        }
      }
    });
  });
}