"use strict";

var trspThirdBlockSelectList = document.querySelector('.trsp-16-third-block-select-list-js');

if (trspThirdBlockSelectList) {
  var trspThirdBlockSelectListInputsChildList = trspThirdBlockSelectList.querySelectorAll('input');
  var swiperFirst, swiperSecond, swiperThird, swiperForth;
  var trspThirdBlockSlidersListItemRusult = document.querySelectorAll('.trsp-16-third-block-sliders-list-item-js');
  var trspThirdBlockSlidersList = document.querySelector('.trsp-16-third-block-sliders-list-js'); ///create sliders for all checkboxes with sliders 

  if (trspThirdBlockSlidersList) {
    var checkForSwiperSliderAmountSliders = function checkForSwiperSliderAmountSliders(sliderContainerElement) {
      var currentsliderContainerElement = document.querySelector(sliderContainerElement);
      var currentsliderContainerElementChildList = currentsliderContainerElement.firstElementChild.children;

      if (currentsliderContainerElementChildList.length <= 3) {
        currentsliderContainerElement.parentNode.querySelector('.more-services-swiper-button-wrapper').style.display = 'none';
      }

      if (currentsliderContainerElementChildList.length <= 3 && window.innerWidth >= 1025) {
        addNoSwiping();
      }

      if (currentsliderContainerElementChildList.length <= 2 && window.innerWidth <= 1024 && window.innerWidth >= 481) {
        addNoSwiping();
      }

      if (currentsliderContainerElementChildList.length <= 1 && window.innerWidth <= 480) {
        addNoSwiping();
      }

      function addNoSwiping() {
        currentsliderContainerElement.classList.add('swiper-no-swiping');
      }
    };

    var trspThirdBlockSlidersListItemsList = document.querySelectorAll('.trsp-16-third-block-sliders-list-item-js'); ////create variables for each slider elements 

    trspThirdBlockSlidersListItemsList.forEach(function (sliderMainElemetItem) {
      var swiperContainerMoreServicesWrapperInsideOuterContainer = sliderMainElemetItem.querySelector('.swiper-container--more-services-trsp-16');
      var swiperContainerMoreServicesWrapper = sliderMainElemetItem.querySelector('.swiper-container--more-services-wrapper--trsp-16');
      var swiperSlideMoreServicesList = sliderMainElemetItem.querySelectorAll('.swiper-slide--more-services'); ////add needed classe for create slider

      swiperContainerMoreServicesWrapperInsideOuterContainer.classList.add('swiper-container');
      swiperContainerMoreServicesWrapper.classList.add('swiper-wrapper');
      swiperSlideMoreServicesList.forEach(function (sliderItemElement) {
        sliderItemElement.classList.add('swiper-slide');
      });
    });
    checkForSwiperSliderAmountSliders('.swiper-container--more-services-trsp-16-1');
    checkForSwiperSliderAmountSliders('.swiper-container--more-services-trsp-16-2');
    checkForSwiperSliderAmountSliders('.swiper-container--more-services-trsp-16-3');
    checkForSwiperSliderAmountSliders('.swiper-container--more-services-trsp-16-4');
    swiperFirst = new Swiper('.swiper-container--more-services-trsp-16-1', {
      slidesPerView: 3,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        1024: {
          slidesPerView: 2
        },
        480: {
          freeMode: true,
          slidesPerView: 'auto',
          spaceBetween: 10
        }
      }
    });
    swiperSecond = new Swiper('.swiper-container--more-services-trsp-16-2', {
      slidesPerView: 3,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        1024: {
          slidesPerView: 2
        },
        480: {
          freeMode: true,
          slidesPerView: 'auto',
          spaceBetween: 10
        }
      }
    });
    swiperThird = new Swiper('.swiper-container--more-services-trsp-16-3', {
      slidesPerView: 3,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        1024: {
          slidesPerView: 2
        },
        480: {
          freeMode: true,
          slidesPerView: 'auto',
          spaceBetween: 10
        }
      }
    });
    swiperForth = new Swiper('.swiper-container--more-services-trsp-16-4', {
      slidesPerView: 3,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        1024: {
          slidesPerView: 2
        },
        480: {
          freeMode: true,
          slidesPerView: 'auto',
          spaceBetween: 10
        }
      }
    });
  } ///// hide all elements after sliders creating


  trspThirdBlockSlidersListItemRusult.forEach(function (item) {
    item.classList.remove('trsp-16-third-block-sliders-list-item-active-js');
  });
  trspThirdBlockSlidersListItemRusult[0].classList.add('trsp-16-third-block-sliders-list-item-active-js');
  trspThirdBlockSelectListInputsChildList.forEach(function (item) {
    item.addEventListener('click', function () {
      trspThirdBlockSlidersListItemRusult.forEach(function (element) {
        element.classList.remove('trsp-16-third-block-sliders-list-item-active-js');
      });
      var currentResultItem = Array.from(trspThirdBlockSlidersListItemRusult).find(function (el) {
        return el.id == item.id;
      });
      currentResultItem.classList.add('trsp-16-third-block-sliders-list-item-active-js');
      swiperFirst.slideTo(0);
      swiperSecond.slideTo(0);
      swiperThird.slideTo(0);
      swiperForth.slideTo(0);
    });
  });
}