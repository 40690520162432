"use strict";

var inputName = document.getElementById('name');
var labelName = document.getElementById('label_name');

if (inputName && labelName) {
  labelName.style.cursor = 'pointer';
  labelName.addEventListener('click', function () {
    labelName.classList.add('hide');
    inputName.focus();
  });
  inputName.addEventListener('focus', function () {
    labelName.classList.add('hide');
  });
  inputName.addEventListener('blur', function () {
    if (!inputName.value) {
      labelName.classList.remove('hide');
    }
  });
}

var inputCompany = document.getElementById('company');
var labelCompany = document.getElementById('label_company');

if (inputCompany && labelCompany) {
  labelCompany.style.cursor = 'pointer';
  labelCompany.addEventListener('click', function () {
    labelCompany.classList.add('hide');
    inputCompany.focus();
  });
  inputCompany.addEventListener('focus', function () {
    labelCompany.classList.add('hide');
  });
  inputCompany.addEventListener('blur', function () {
    if (!inputCompany.value) {
      labelCompany.classList.remove('hide');
    }
  });
}

var inputCompanySite = document.getElementById('company_site');
var labelCompanySite = document.getElementById('label_company_site');

if (inputCompanySite && labelCompanySite) {
  labelCompanySite.style.cursor = 'pointer';
  labelCompanySite.addEventListener('click', function () {
    labelCompanySite.classList.add('hide');
    inputCompanySite.focus();
  });
  inputCompanySite.addEventListener('focus', function () {
    labelCompanySite.classList.add('hide');
  });
  inputCompanySite.addEventListener('blur', function () {
    if (!inputCompanySite.value) {
      labelCompanySite.classList.remove('hide');
    }
  });
}

var inputEmail = document.getElementById('user-email');
var labelEmail = document.getElementById('label_email');

if (inputEmail && labelEmail) {
  labelEmail.style.cursor = 'pointer';
  labelEmail.addEventListener('click', function () {
    labelEmail.classList.add('hide');
    inputEmail.focus();
  });
  inputEmail.addEventListener('focus', function () {
    labelEmail.classList.add('hide');
  });
  inputEmail.addEventListener('blur', function () {
    if (!inputEmail.value) {
      labelEmail.classList.remove('hide');
    }
  });
}

var inputTel = document.getElementById('tel');
var labelTel = document.getElementById('label_tel');

if (inputTel && labelTel) {
  labelTel.style.cursor = 'pointer';
  labelTel.addEventListener('click', function () {
    labelTel.classList.add('hide');
    inputTel.focus();
  });
  inputTel.addEventListener('focus', function () {
    labelTel.classList.add('hide');
  });
  inputTel.addEventListener('blur', function () {
    if (!inputTel.value) {
      labelTel.classList.remove('hide');
    }
  });
}

var inputCountryLoading = document.getElementById('country_loading');
var labelCountryLoading = document.getElementById('label_country_loading');

if (inputCountryLoading && labelCountryLoading) {
  labelCountryLoading.style.cursor = 'pointer';
  labelCountryLoading.addEventListener('click', function () {
    labelCountryLoading.classList.add('hide');
    inputCountryLoading.focus();
  });
  inputCountryLoading.addEventListener('focus', function () {
    labelCountryLoading.classList.add('hide');
  });
  inputCountryLoading.addEventListener('blur', function () {
    if (!inputCountryLoading.value) {
      labelCountryLoading.classList.remove('hide');
    }
  });
}

var inputCityLoading = document.getElementById('city_loading');
var labelCityLoading = document.getElementById('label_city_loading');

if (inputCityLoading && labelCityLoading) {
  labelCityLoading.style.cursor = 'pointer';
  labelCityLoading.addEventListener('click', function () {
    labelCityLoading.classList.add('hide');
    inputCityLoading.focus();
  });
  inputCityLoading.addEventListener('focus', function () {
    labelCityLoading.classList.add('hide');
  });
  inputCityLoading.addEventListener('blur', function () {
    if (!inputCityLoading.value) {
      labelCityLoading.classList.remove('hide');
    }
  });
}

var inputCountryDestination = document.getElementById('country_destination');
var labelCountryDestination = document.getElementById('label_country_destination');

if (inputCountryDestination && labelCountryDestination) {
  labelCountryDestination.style.cursor = 'pointer';
  labelCountryDestination.addEventListener('click', function () {
    labelCountryDestination.classList.add('hide');
    inputCountryDestination.focus();
  });
  inputCountryDestination.addEventListener('focus', function () {
    labelCountryDestination.classList.add('hide');
  });
  inputCountryDestination.addEventListener('blur', function () {
    if (!inputCountryDestination.value) {
      labelCountryDestination.classList.remove('hide');
    }
  });
}

var inputCityDestination = document.getElementById('city_destination');
var labelCityDestination = document.getElementById('label_city_destination');

if (inputCityDestination && labelCityDestination) {
  labelCityDestination.style.cursor = 'pointer';
  labelCityDestination.addEventListener('click', function () {
    labelCityDestination.classList.add('hide');
    inputCityDestination.focus();
  });
  inputCityDestination.addEventListener('focus', function () {
    labelCityDestination.classList.add('hide');
  });
  inputCityDestination.addEventListener('blur', function () {
    if (!inputCityDestination.value) {
      labelCityDestination.classList.remove('hide');
    }
  });
}

var inputCustomsClearance = document.getElementById('customs_clearance');
var labelCustomsClearance = document.getElementById('label_customs_clearance');

if (inputCustomsClearance && labelCustomsClearance) {
  labelCustomsClearance.style.cursor = 'pointer';
  labelCustomsClearance.addEventListener('click', function () {
    labelCustomsClearance.classList.add('hide');
    inputCustomsClearance.focus();
  });
  inputCustomsClearance.addEventListener('focus', function () {
    labelCustomsClearance.classList.add('hide');
  });
  inputCustomsClearance.addEventListener('blur', function () {
    if (!inputCustomsClearance.value) {
      labelCustomsClearance.classList.remove('hide');
    }
  });
}

var inputCargo = document.getElementById('cargo');
var labelCargo = document.getElementById('label_cargo');

if (inputCargo && labelCargo) {
  labelCargo.style.cursor = 'pointer';
  labelCargo.addEventListener('click', function () {
    labelCargo.classList.add('hide');
    inputCargo.focus();
  });
  inputCargo.addEventListener('focus', function () {
    labelCargo.classList.add('hide');
  });
  inputCargo.addEventListener('blur', function () {
    if (!inputCargo.value) {
      labelCargo.classList.remove('hide');
    }
  });
}

var inputWeight = document.getElementById('weight');
var labelWeight = document.getElementById('label_weight');

if (inputWeight && labelWeight) {
  labelWeight.style.cursor = 'pointer';
  labelWeight.addEventListener('click', function () {
    labelWeight.classList.add('hide');
    inputWeight.focus();
  });
  inputWeight.addEventListener('focus', function () {
    labelWeight.classList.add('hide');
  });
  inputWeight.addEventListener('blur', function () {
    if (!inputWeight.value) {
      labelWeight.classList.remove('hide');
    }
  });
}

var inputBulk = document.getElementById('bulk');
var labelBulk = document.getElementById('label_bulk');

if (inputBulk && labelBulk) {
  labelBulk.style.cursor = 'pointer';
  labelBulk.addEventListener('click', function () {
    labelBulk.classList.add('hide');
    inputBulk.focus();
  });
  inputBulk.addEventListener('focus', function () {
    labelBulk.classList.add('hide');
  });
  inputBulk.addEventListener('blur', function () {
    if (!inputBulk.value) {
      labelBulk.classList.remove('hide');
    }
  });
}

var buttonToogle = document.getElementById('button-toogle');

if (buttonToogle) {
  buttonToogle.onclick = function (e) {
    e.preventDefault();
    toogleCountryLoading = inputCountryLoading.value;
    toogleCountryDestination = inputCountryDestination.value;
    toogleCityLoading = inputCityLoading.value;
    toogleCityDestination = inputCityDestination.value;
    inputCountryLoading.value = toogleCountryDestination;
    inputCountryDestination.value = toogleCountryLoading;
    inputCityLoading.value = toogleCityDestination;
    inputCityDestination.value = toogleCityLoading;

    if (!inputCountryLoading.value) {
      labelCountryLoading.classList.remove('hide');
    } else {
      labelCountryLoading.classList.add('hide');
    }

    if (!inputCountryDestination.value) {
      labelCountryDestination.classList.remove('hide');
    } else {
      labelCountryDestination.classList.add('hide');
    }

    if (!inputCityLoading.value) {
      labelCityLoading.classList.remove('hide');
    } else {
      labelCityLoading.classList.add('hide');
    }

    if (!inputCityDestination.value) {
      labelCityDestination.classList.remove('hide');
    } else {
      labelCityDestination.classList.add('hide');
    }
  };
}

var formTypeSelectBoxContainerRequest = document.querySelector(".form-type-select-box-container-request-js");
var transportationInfoInput = document.querySelector('.transportation-info-js');
var seventhSupporFormSubmitOnlineRequestBtn = document.querySelector('.seventh-suppor-form-submit-online-request-btn-js'); // let val = 0;
// console.log(val)

if (formTypeSelectBoxContainerRequest) {
  var dropDownHendler = function dropDownHendler(event) {
    var target = event.target;
    inputBtnRequest.textContent = target.textContent;
    transportationInfoInput.value = target.textContent;
  };

  var inputBtnRequest = formTypeSelectBoxContainerRequest.querySelector(".input-btn-request-js");
  var dropdownInfoRequesrtForm = formTypeSelectBoxContainerRequest.querySelector(".dropdown-info-requesrt-form-js");
  var dropdownInfoRequesrtFormChildren = dropdownInfoRequesrtForm.children;
  inputBtnRequest.textContent = dropdownInfoRequesrtFormChildren[0].textContent;
  Array.from(dropdownInfoRequesrtFormChildren).forEach(function (el) {
    el.addEventListener("click", dropDownHendler);
  });
} ///////for input file 


var modalCalculatorForForm = document.querySelector('.modal-calculator-for-form-js');
var calculatorDeliveryRequestForForm = document.querySelector('.calculator-delivery-request-for-form-js');
var modalDeliveryScheduleForForm = document.querySelector('.modal-delivery-schedule-for-form-js'); // var inputfileOnlineRequest = document.querySelector('.inputfile-online-request-js');

var mainInputfileOnlineRequest = document.querySelector('.inputfile-online-request-js'); ///////for slider with results table like groupage from europe page

var submitApplicationDeliveryScheduleGroupageCargoSlider = document.querySelector('.submit-application-delivery-schedule-groupage-cargo-slider-js'); ///////if page has 2 or more forms class for bottom long form

var supportFormGrayBottrom = document.querySelector('.support-form-gray--bottrom-js');

function callAtachAmountChecker() {
  if (submitApplicationDeliveryScheduleGroupageCargoSlider) {
    chekInputForAttachedFilesAmount(submitApplicationDeliveryScheduleGroupageCargoSlider);
    chekInputForAttachedFilesAmount(supportFormGrayBottrom);
    return;
  }

  if (modalCalculatorForForm) {
    chekInputForAttachedFilesAmount(modalCalculatorForForm);
    chekInputForAttachedFilesAmount(calculatorDeliveryRequestForForm);
    return;
  } // if(calculatorDeliveryRequestForForm){
  //   chekInputForAttachedFilesAmount(calculatorDeliveryRequestForForm);
  //   return;
  // }


  if (modalDeliveryScheduleForForm) {
    chekInputForAttachedFilesAmount(modalDeliveryScheduleForForm);
    return;
  } else {
    if (mainInputfileOnlineRequest) {
      chekInputForAttachedFilesAmount();
      return;
    }
  }
}

callAtachAmountChecker(); // else{
//   // chekInputForAttachedFilesAmount();
// }
// if(inputfileOnlineRequest){
//   chekInputForAttachedFilesAmount();  
// }

function chekInputForAttachedFilesAmount(doc) {
  // console.log(doc);
  if (doc == undefined) {
    doc = document;
  }

  var inputfileOnlineRequest = doc.querySelector('.inputfile-online-request-js'); // console.log(inputfileOnlineRequest);

  if (inputfileOnlineRequest) {
    inputfileOnlineRequest.addEventListener("change", function () {
      // console.log(doc);
      var userFileOnlineRequestDescr = doc.querySelector('.user-file-online-request-descr-js');
      var attacheFilesAmountMax = 4;
      var filesListInput = doc.querySelector('.files-list-input-js');
      var userMessageInput = doc.querySelector('.user-message-input-js');
      var companyMessageInput = doc.querySelector('.company-message-input-js');
      var seventhSupporFormSubmitOnlineRequestBtn = doc.querySelector('.seventh-suppor-form-submit-online-request-btn-js');
      var currentFilesList = inputfileOnlineRequest.files;
      userFileOnlineRequestDescr.style.color = "#191e2d";

      if (currentFilesList.length > attacheFilesAmountMax) {
        userFileOnlineRequestDescr.textContent = "\u041F\u0440\u0435\u0432\u044B\u0448\u0435\u043D\u043E \u043C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u043E \u0434\u043E\u043F\u0443\u0441\u0442\u0438\u043C\u043E\u0435 \u043A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0444\u0430\u0439\u043B\u043E\u0432(".concat(attacheFilesAmountMax, ")");
        userFileOnlineRequestDescr.style.color = "#FF0000";
        seventhSupporFormSubmitOnlineRequestBtn.setAttribute('disabled', 'disabled');
      } else {
        var filesNamesString = '';
        seventhSupporFormSubmitOnlineRequestBtn.removeAttribute('disabled', 'disabled');

        for (var key in currentFilesList) {
          if (!isNaN(key)) {
            if (filesNamesString !== '') {
              filesNamesString += ",<br> ";
            }

            filesNamesString += "".concat(currentFilesList[key].name);
          }
        }

        userFileOnlineRequestDescr.innerHTML = filesNamesString;
        userMessageInput.value = "\u0412\u044B \u043F\u0440\u0438\u043A\u0440\u0435\u043F\u0438\u043B\u0438 \u0444\u0430\u0439\u043B\u044B:";
        companyMessageInput.value = "\u041F\u0440\u0438\u043A\u0440\u0435\u043F\u043B\u0435\u043D\u043D\u044B\u0435 \u0444\u0430\u0439\u043B\u044B:";
        filesListInput.value += "".concat(userFileOnlineRequestDescr.textContent, " ");
      }
    });
  }
} ////thankU popUp // 


function showThankUpopUp(formClassName) {
  var thankYouPopUpWrapper = document.querySelector('.thank-you-pop-up-wrapper-js');

  if (formClassName == '.online-request-form-js' || formClassName == '.support-form-delivery-request-js' || formClassName == '.submit-application-delivery-schedule-groupage-cargo-slider-js') {
    window.scroll(0, 0);
  }

  if (formClassName == '.support-form-gray-js') {
    if (window.innerWidth <= 480) {
      window.scroll(0, 0);
      thankYouPopUpWrapper.classList.add('thank-you-pop-up__wrapper--bind-to-modal-calc');
    }
  }

  if (formClassName == '.support-form-gray-js--orange') {
    thankYouPopUpWrapper.classList.add('thank-you-pop-up__wrapper--bind-to-modal-calc');

    if (window.innerWidth <= 480) {
      window.scroll(0, 0);
    }
  }

  thankYouPopUpWrapper.classList.remove('visually-hidden');
  setTimeout(function () {
    document.querySelector(formClassName).submit();
  }, 200);
}