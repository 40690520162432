

var buttonHome = document.querySelector(".button-home-js");

if (buttonHome) {
  var scrollBodyToTop = function scrollBodyToTop() {
    window.scrollTo(0, 0);
    return;
  };

  buttonHome.addEventListener("click", scrollBodyToTop);
  buttonHome.addEventListener("touchend", scrollBodyToTop);
}