"use strict";

var deliveryCalendar = document.querySelector(".delivery-calendar-js");
var calendarChoiceDeliveryRequestBtn = document.querySelector(".calendar-choice-btn-delivery-request-js");
var supportFormInputDeliveryRequest = document.querySelector(".support-form-input-delivery-request-js"); //// serviceInputs btn-for-show calendar at delivery-request form on calculator page
//select DOMelements

var checkFromCountryBtn = document.querySelector(".check-from-country-btn"); /// inputs of select element of europe

var resultFromDoorToDoorEurope = document.querySelector(".europe-result-from-door-to-door-js");
var resultFromDoorToTerminalEurope = document.querySelector(".europe-result-from-door-to-terminal-js");
var resultsFootnoteItemDescrEurope = document.querySelector(".europe-results-footnote-item-descr-js");
var resultsFootnoteDateItemTextDaysShippingEurope = document.querySelector(".europe-results-footnote__date-item-text-days-shipping-js");
var resultsFootnoteDateItemTextDaysArrivedEurope = document.querySelector(".europe-results-footnote__date-item-text-days-arrived-js");
var profitableLocationTxtDynamic = document.querySelector(".profitable-location-txt-dynamic-js"); // inputs of select element of china

var chinaCalendarWrapperOptionsFootnote = document.querySelector(".china-calendar-wrapper-options-footnote-js");
var chinaResultFromStationToStation = document.querySelector(".china-result-from-station-to-station-js");
var chinaResultFromTerminalToTerminalBig = document.querySelector(".china-result-from-terminal-to-terminal-big-js");
var chinaResultFromTerminalToTerminalSmall = document.querySelector(".china-result-from-terminal-to-terminal-small-js");
var chinaResultsFootnoteItemTitle = document.querySelector(".china-results-footnote-item-title-js");
var chinaResultsFootnoteItemDescr = document.querySelector(".china-results-footnote-item-descr-js");
var chinaResultsFootnoteDateItemText = document.querySelector(".china-results-footnote-date-item-text-js"); // inputs of select element of service

var serviceResultFromTerminalToDoor = document.querySelector(".service-result-from-terminal-to-door-js");
var serviceResultFromTerminalToDoorSmall = document.querySelector(".service-result-from-terminal-to-door-small-js");
var serviceResultFromDoorToDoor = document.querySelector(".service-result-from-door-to-door-js");
var serviceResultFromDoorToDoorSmall = document.querySelector(".service-result-from-door-to-door-small-js");
var serviceContainerResultFromTerminalToDoor = document.querySelector(".service-container-result-from-terminal-to-door-js");
var serviceContainerResultFromDoorToDoor = document.querySelector(".service-container-result-from-door-to-door-js");
var serviceResultsFootnoteItemTitle = document.querySelector(".service-results-footnote-item-title-js");
var serviceResultsFootnoteItemDescr = document.querySelector(".service-results-footnote-item-descr-js");
var serviceResultsFootnoteDateItemText = document.querySelector(".service-results-footnote-date-item-text-js");
var serviceShipResultsFootnoteDateItemText = document.querySelector(".service-ship-results-footnote-date-item-text-js"); ///calendar DOMelements

var chinaCalendarDates = document.querySelector(".china-calendar-dates-js");
var chinaMonthName = document.querySelector(".china-month-name-js");
var chinaMonthYearnum = document.querySelector(".china-month-yearnum-js"); //////serviceInputs

var calendarInput = document.querySelector(".calendar-input-js");
var calendarChoiceBtn = document.querySelector(".calendar-choice-btn-js"); // console.log(calendarChoiceDeliveryRequestBtn);
////buttons
/// for europe month controls

var europeCalendarMonthControlBack = document.querySelector(".europe-calendar-month-control-back-js");
var europeCalendarMonthControlForward = document.querySelector(".europe-calendar-month-control-forward-js"); /// for  china month  controls

var chinaCalendarMonthControlBack = document.querySelector(".china-calendar-month-control-back-js");
var chinaCalendarMonthControlForward = document.querySelector(".china-calendar-month-control-forward-js"); // for service calendar

var serviceCalendarMonthControlBack = document.querySelector(".service-calendar-month-control-back-js");
var serviceCalendarMonthControlForward = document.querySelector(".service-calendar-month-control-forward-js"); ////modal&hidden elements

var calendarService = document.querySelector(".calendar-service-js");
var calendarChoiceBtnsText = document.querySelector(".calendar-choice-btns-text-js");
var calendarChoiceBtnsCalendarImg = document.querySelector(".calendar-choice-btns-calendar-img-js");
var calendarChoiceBtns = document.querySelector(".calendar-choice-btns-js"); /////modaldeliverywindow

var modalDeliverySchedulLoadFromUserAdressInput = document.querySelector(".modal-delivery-schedul-load-from-user-adress-input-js");
var modalDeliveryScheduleLoadFromAdressBtn = document.querySelector(".modal-delivery-schedule-load-from-adress-btn-js");
var seventhSupportFormSubmitDeliverySheduleModal = document.querySelector('.seventh-support-form-submit-delivery-shedule-modal-js');
var modalDeliverySchedulUsersDataDestTextfromUser = document.querySelector(".modal-delivery-schedul-users-data-dest-text-from-user-js");
var modalDeliveryScheduleUsersDataDateTextFromUser = document.querySelector(".modal-delivery-schedule-users-data-date-text-from-user-js");
var modalDeliveryScheduleLoadFromRadioTakeInput = document.querySelector(".modal-delivery-schedule-load-from-radio-take-input-js");
var modalDeliveryScheduleLoadFromRadioGiveInput = document.querySelector(".modal-delivery-schedule-load-from-radio-give-input-js");
var modalDeliveryScheduleCargoParametrsItemInputs = document.querySelectorAll(".modal-delivery-schedule-cargo-parametrs-item-input-js");
var modalDeliveryScheduleServiceNote = document.querySelector('.modal-delivery-schedule-service-note-js');
var modalDeliverySchedule = document.querySelector(".modal-delivery-schedule-js");
var modalDeliveryScheduleCloseBtn = document.querySelector(".modal-delivery-schedule-close-btn-js");
var modalDeliveryScheduleWrapper = document.querySelector(".modal-delivery-schedule-wrapper-js");
var serviceCalendarRequestBtn = document.querySelector('.service-calendar-request-btn-js'); //// hidden/shown calendar wrapers

var calendarWrapperService = document.querySelector(".calendar-wrapper-service-js");
var calendarWrapperFastTrains = document.querySelector(".calendar-wrapper-fast-trains-js ");
var checkServiceBtn = document.querySelector(".check-service-btn-js "); //// 

var deliveryScheduleChinaFasttrainsDataPath = "./json-data/deliveryScheduleChinaFasttrainsData.json";
var deliveryScheduleEuropeCalandarDataPath = "./json-data/deliveryScheduleEuropeCalandarData.json";
var deliveryScheduleServiceDataPath = "./json-data/deliveryScheduleServiceData.json";
var deliverySheduleCommonCalendarDataBasePath = "./json-data/deliverySheduleCommonCalendarDataBase.json";
var inputForMsk = [{
  id: "Москва и МО"
}]; //// month names

var monthNameArr = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
var monthNameArr2 = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"]; ////current date

var date = new Date(); //////current date for test
// const date = new Date(2020,11,30);

var pageOfsetDeliveryShedule;
var dropDownList = "";
var selectFromEuropeDataArr;
var selectEuropeDestDataArr;
var selectFromFasttrainsChinaArr;
var selectFromServiceCalendarDataArr;
var calendarsData;
var europeCalendarData;
var chinaCalendarsData;
var serviceCalendarData;
var europeDataOrangePoint = {};
var chinaDataOrangePoint = {};
var serviceDataOrangePoint = {};
var currentEuropeResultsForModal = {};
var currentChinaResultsForModal = {};
var currentServeceResultsForModal = {};
var currentDestFirstName = ""; ////for calc page 

if (calendarChoiceDeliveryRequestBtn) {
  var calendarChoiceBtnDeliveryRequestheadler = function calendarChoiceBtnDeliveryRequestheadler() {
    createBottomMarginAfterCalendar();

    if (calendarInputCalculator.classList.contains("visually-hidden")) {
      calendarInputCalculator.classList.remove("visually-hidden");
      calculatorContainer.style.marginBottom = '220px';
      var calendarRequestWrapperInside = document.querySelector('.calendar-request-wrapper-inside-js');
      calendarRequestWrapperInside.classList.add('calendar-service-bgc');
      calendarRequestWrapperInside.addEventListener('click', function () {
        calendarInputCalculator.classList.add("visually-hidden");
        createBottomMarginAfterCalendar();
        calendarRequestWrapperInside.classList.remove('calendar-service-bgc');
      });
      document.querySelector(".input-calendar-dates-js").addEventListener("click", function () {
        if (event.target.classList.contains("calendar__dates-item-china-active-color-text")) {
          var data = {};
          data.currentYear = serviceDataOrangePoint.year;
          data.currentMonth = serviceDataOrangePoint.month;
          data.currentDay = serviceDataOrangePoint.day;
          loadServiceCalendarDataCurrenrtButtonDay(data);
          calendarInputCalculator.classList.add("visually-hidden");
          calendarRequestWrapperInside.classList.remove('calendar-service-bgc');
          createBottomMarginAfterCalendar();
        }
      });
    } else {
      calendarInputCalculator.classList.add("visually-hidden");
      createBottomMarginAfterCalendar();
    }

    function createBottomMarginAfterCalendar() {
      calculatorContainer.style.marginBottom = '130px';

      if (window.innerWidth <= 480) {
        calculatorContainer.style.marginBottom = '40px';
      }
    }
  };

  var createCurrentCalculatorCalendar = function createCurrentCalculatorCalendar(data) {
    calendarsData = data;
    serviceCalendarData = makeStartForCalendars(calendarsData[2], ".input-calendar-dates-js", ".input-month-name-js", ".input-month-yearnum-js", true);
    var actuallyData = serviceCalendarData; // actuallyData.currentDay += 1;

    loadServiceCalendarDataCurrenrtButtonDay(actuallyData);
    inputCalendarMonthControlBack.addEventListener("click", loadMonth.bind(null, event, -1, serviceCalendarData, true));
    inputCalendarMonthControlForward.addEventListener("click", loadMonth.bind(null, event, 1, serviceCalendarData, true));
  };

  var _supportFormInputDeliveryRequest = document.querySelector('.support-form-input-delivery-request-js');

  var inputCalendarMonthControlBack = document.querySelector('.input-calendar-month-control-back-js');
  var inputCalendarMonthControlForward = document.querySelector('.input-calendar-month-control-forward-js');
  var calendarChoiceBtnDeliveryRequest = document.querySelector('.calendar-choice-btn-delivery-request-js');
  var calendarInputCalculator = document.querySelector('.calendar-input-js-calculator'); // const calculatorContainer =  document.querySelector('.calculator-1-container-js');

  calendarChoiceBtnDeliveryRequest.addEventListener('click', calendarChoiceBtnDeliveryRequestheadler);
  getJsonDataForDeliverySchedule(deliverySheduleCommonCalendarDataBasePath).then(createCurrentCalculatorCalendar);
}

if (deliveryCalendar) {
  var deliverySheduleOnLoad = function deliverySheduleOnLoad() {
    // calendarWrapperFastTrains.classList.remove("visually-hidden");
    // loadServiceCalendarData();
    loadFastTrainsData();
    wrappersToggler();
    showCurrentCheckBox(onFastTrainInput, seaPlusTraineInput);
  };

  var showCurrentCheckBox = function showCurrentCheckBox(activeElement, notActiveElement) {
    // console.log(activeElement.nextElementSibling);
    activeElement.nextElementSibling.style.background = '#012169';
    notActiveElement.nextElementSibling.style.background = '#FFFFFF';
  };

  var wrappersToggler = function wrappersToggler() {
    seaPlusTraineInput.addEventListener("click", function () {
      serviceDataOrangePoint.day = '';
      serviceDataOrangePoint.month = date.getMonth();
      serviceDataOrangePoint.year = date.getFullYear();
      loadServiceCalendarData();
      showCurrentCheckBox(seaPlusTraineInput, onFastTrainInput);
    });
    onFastTrainInput.addEventListener("click", function () {
      createDecoratedCalendar(chinaCalendarsData, false);
      chinaDataOrangePoint.day = '';
      chinaDataOrangePoint.month = date.getMonth();
      chinaDataOrangePoint.year = date.getFullYear();
      loadFastTrainsData();
      showCurrentCheckBox(onFastTrainInput, seaPlusTraineInput);
    });
  };

  var loadFastTrainsData = function loadFastTrainsData() {
    // serviceDataOrangePoint = {};
    calendarWrapperFastTrains.classList.remove("visually-hidden");
    calendarWrapperService.classList.add("visually-hidden");
    getJsonDataForDeliverySchedule(deliveryScheduleChinaFasttrainsDataPath).then(createFastTrainsRes);
    getJsonDataForDeliverySchedule(deliverySheduleCommonCalendarDataBasePath).then(creatCurrentFastChinaTrainsCalendar);
  };

  var loadServiceCalendarData = function loadServiceCalendarData() {
    calendarWrapperFastTrains.classList.add("visually-hidden");
    calendarWrapperService.classList.remove("visually-hidden");
    getJsonDataForDeliverySchedule(deliveryScheduleServiceDataPath).then(createServiceCalendarRes);
    getJsonDataForDeliverySchedule(deliverySheduleCommonCalendarDataBasePath).then(createCurrentServiceCalendar);
    calendarChoiceBtns.addEventListener("click", calendarChoiseBtnheadler);
  };

  var calendarChoiseBtnheadler = function calendarChoiseBtnheadler() {
    if (calendarService.classList.contains("visually-hidden")) {
      calendarService.classList.remove("visually-hidden");
      calendarWrapperService.style.marginBottom = '220px';

      if (window.innerWidth < 1024) {
        calendarWrapperService.style.marginBottom = '230px';
      }

      var calendarRequestWrapperInside = document.querySelector('.calendar-request-wrapper-inside-js');
      calendarRequestWrapperInside.classList.add('calendar-service-bgc');
      calendarRequestWrapperInside.addEventListener('click', function () {
        calendarService.classList.add("visually-hidden");
        makeMarginBottonAfterCloseServiceCalendar();
        calendarRequestWrapperInside.classList.remove('calendar-service-bgc');
      });
      document.querySelector(".service-calendar-dates-js").addEventListener("click", function () {
        if (event.target.classList.contains("calendar__dates-item-china-active-color-text")) {
          var data = {};
          data.currentYear = serviceDataOrangePoint.year;
          data.currentMonth = serviceDataOrangePoint.month;
          data.currentDay = serviceDataOrangePoint.day; // // console.log(serviceDataOrangePoint.day);
          // console.log(data);

          loadServiceCalendarDataCurrenrtButtonDay(data);
          calendarService.classList.add("visually-hidden");
          calendarRequestWrapperInside.classList.remove('calendar-service-bgc');
          makeMarginBottonAfterCloseServiceCalendar();
        }
      });
    } else {
      calendarService.classList.add("visually-hidden");
      makeMarginBottonAfterCloseServiceCalendar();
    }

    function makeMarginBottonAfterCloseServiceCalendar() {
      calendarWrapperService.style.marginBottom = '90px';

      if (window.innerWidth < 1024) {
        calendarWrapperService.style.marginBottom = '40px';
      }
    }
  }; // window.addEventListener('click',winheadler);
  // function winheadler()


  var creatEuropeResObj = function creatEuropeResObj(data) {
    selectFromEuropeDataArr = data;
    createCurrentInput(selectFromEuropeDataArr, checkFromEuropeCountryBtn, makeResultForEuropeTable);
  };

  var createEuropeDestObj = function createEuropeDestObj(data) {
    selectEuropeDestDataArr = data;
    createCurrentInput(selectEuropeDestDataArr, checkFromEuropeDestinationBtn);
  };

  var createFastTrainsRes = function createFastTrainsRes(data) {
    selectFromFasttrainsChinaArr = data;
    createCurrentInput(selectFromFasttrainsChinaArr, checkOnFastTraineBtn, makeResultForFastTrains);
  }; ////////show delivery shedule modal with currentData
  // modalDeliverySchedule.style.display = 'none';


  var showDeliveryScheduleModal = function showDeliveryScheduleModal(event, place, point, isServive, firstDest) {
    console.log(point); // console.log(point);

    currentDestFirstName = firstDest;
    pageOfsetDeliveryShedule = window.scrollY;
    modalDeliverySchedule.style.display = 'block';

    if (window.innerWidth <= 480) {
      window.scrollTo(0, 0); // document.querySelector('html').classList.add('stop-scroll');
      // document.querySelector('body').classList.add('stop-scroll');  

      document.querySelector('.header-wrapper-js').style.display = 'none';
      document.querySelector('.main-wrapper-delivery-schedule-js').style.display = 'none';
      document.querySelector('.footer-wrapper').style.display = 'none';
    }

    var placeStr = place.from;
    var numStr = "";

    if (place.To) {
      placeStr = place.from + " " + "-" + " " + place.To;
    }

    modalDeliverySchedulLoadFromUserAdressInput.classList.add("visually-hidden");

    if (isServive) {
      modalDeliveryScheduleServiceNote.classList.remove('visually-hidden');
    } else {
      modalDeliveryScheduleServiceNote.classList.add('visually-hidden');
    }

    modalDeliverySchedulUsersDataDestTextfromUser.textContent = placeStr; // console.log(point);

    modalDeliveryScheduleUsersDataDateTextFromUser.textContent = "".concat(point.day, " ").concat(monthNameArr2[point.month], " ").concat(point.year); // modalDeliveryScheduleLoadFromAdressBtn.removeEventListener("click", showHideAdreesField);

    modalDeliveryScheduleLoadFromAdressBtn.addEventListener("click", showHideAdreesField);
    modalDeliveryScheduleLoadFromRadioTakeInput.addEventListener("click", function () {
      modalDeliveryScheduleLoadFromRadioTakeInput.nextElementSibling.classList.add("delivery-modal-label--active");
      modalDeliveryScheduleLoadFromRadioGiveInput.nextElementSibling.classList.remove("delivery-modal-label--active");
      modalDeliveryScheduleLoadFromAdressBtn.classList.remove("visually-hidden");
    });
    modalDeliveryScheduleLoadFromRadioGiveInput.addEventListener("click", function () {
      // modalDeliveryScheduleLoadFromAdressBtn.classList.add("visually-hidden");
      modalDeliverySchedulLoadFromUserAdressInput.classList.add("visually-hidden");
      modalDeliveryScheduleLoadFromRadioGiveInput.nextElementSibling.classList.add("delivery-modal-label--active");
      modalDeliveryScheduleLoadFromRadioTakeInput.nextElementSibling.classList.remove("delivery-modal-label--active");
    }); // modalDeliverySchedule.classList.remove("visually-hidden");
    // modalDeliveryScheduleWrapper.classList.add("modal-bgc");

    modalDeliveryScheduleCloseBtn.addEventListener("click", hideDeliveryScheduleModal); // modalDeliveryScheduleWrapper.addEventListener("click", event => {
    //   if (event.target == modalDeliveryScheduleWrapper) {
    //     hideDeliveryScheduleModal();
    //   }
    //   return;
    // });

    seventhSupportFormSubmitDeliverySheduleModal.addEventListener('click', function () {
      scrollTolastPosition(); // point = {};
    });
  };

  var showHideAdreesField = function showHideAdreesField() {
    if (modalDeliverySchedulLoadFromUserAdressInput.classList.contains("visually-hidden")) {
      modalDeliverySchedulLoadFromUserAdressInput.classList.remove("visually-hidden");
    } else modalDeliverySchedulLoadFromUserAdressInput.classList.add("visually-hidden");

    modalDeliveryScheduleLoadFromRadioTakeInput.nextElementSibling.classList.add("delivery-modal-label--active");
    modalDeliveryScheduleLoadFromRadioGiveInput.nextElementSibling.classList.remove("delivery-modal-label--active");
  };

  var hideDeliveryScheduleModal = function hideDeliveryScheduleModal() {
    // modalDeliverySchedule.classList.add("visually-hidden");
    modalDeliverySchedule.style.display = 'none';
    modalDeliveryScheduleLoadFromAdressBtn.removeEventListener("click", showHideAdreesField); // modalDeliveryScheduleWrapper.classList.remove("modal-bgc");

    clearDataAtModalDelivery();

    if (window.innerWidth <= 480) {
      document.querySelector('.header-wrapper-js').style.display = 'block';
      document.querySelector('.main-wrapper-delivery-schedule-js').style.display = 'block';
      document.querySelector('.footer-wrapper-js').style.display = 'block';
      scrollTolastPosition();
    }
  };

  var scrollTolastPosition = function scrollTolastPosition() {
    // document.querySelector('html').classList.remove('stop-scroll');
    // document.querySelector('body').classList.remove('stop-scroll');
    // console.log(pageOfsetDeliveryShedule);
    window.scrollTo(0, pageOfsetDeliveryShedule);
  };

  var clearDataAtModalDelivery = function clearDataAtModalDelivery() {
    var modalDeliveryScheduleInputs = modalDeliverySchedule.querySelectorAll('input');
    var modalDeliveryScheduleTextAreas = modalDeliverySchedule.querySelectorAll('textarea');
    clearElementsVal(modalDeliveryScheduleInputs);
    clearElementsVal(modalDeliveryScheduleTextAreas);

    function clearElementsVal(elementsArr) {
      elementsArr.forEach(function (el) {
        el.value = '';
        el.style.border = 'none';
      });
    }
  };

  var creatCurrentEuropeCalendars = function creatCurrentEuropeCalendars(data) {
    calendarsData = data; // console.log(calendarsData);

    europeCalendarData = makeStartForCalendars(calendarsData[0], ".europe-calendar-dates-js", ".europe-month-name-js", ".europe-month-yearnum-js"); ////change month

    europeCalendarMonthControlBack.addEventListener("click", loadMonth.bind(null, event, -1, europeCalendarData));
    europeCalendarMonthControlForward.addEventListener("click", loadMonth.bind(null, event, 1, europeCalendarData));
  };

  var creatCurrentFastChinaTrainsCalendar = function creatCurrentFastChinaTrainsCalendar(data) {
    // chinaDataOrangePoint = {};
    // delete chinaDataOrangePoint.year ;
    // delete chinaDataOrangePoint.month;
    // delete chinaDataOrangePoint.day;
    console.log(chinaDataOrangePoint); // serviceDataOrangePoint = {};

    calendarsData = data;
    chinaCalendarsData = makeStartForCalendars(calendarsData[1], ".china-calendar-dates-js", ".china-month-name-js", ".china-month-yearnum-js"); ///change month

    chinaCalendarMonthControlBack.addEventListener("click", loadMonth.bind(null, event, -1, chinaCalendarsData));
    chinaCalendarMonthControlForward.addEventListener("click", loadMonth.bind(null, event, 1, chinaCalendarsData));
  };

  var createCurrentServiceCalendar = function createCurrentServiceCalendar(data) {
    //  serviceDataOrangePoint = {};
    calendarsData = data; // console.log(calendarsData);

    serviceCalendarData = makeStartForCalendars(calendarsData[2], ".service-calendar-dates-js", ".service-month-name-js", ".service-month-yearnum-js");
    var actuallyData = serviceCalendarData;
    actuallyData.currentDay += 1;
    loadServiceCalendarDataCurrenrtButtonDay(actuallyData);
    serviceCalendarMonthControlBack.addEventListener("click", loadMonth.bind(null, event, -1, serviceCalendarData));
    serviceCalendarMonthControlForward.addEventListener("click", loadMonth.bind(null, event, 1, serviceCalendarData));
  };

  var loadGroupCargoById = function loadGroupCargoById(currentId) {} // if (currentId == "on-fast-traine") {
  // }
  ///////made extra json info
  ;

  var makeResultForEuropeTable = function makeResultForEuropeTable(element) {
    // console.log(element);
    resultFromDoorToDoorEurope.textContent = element.fromDorToDoor;
    resultFromDoorToTerminalEurope.textContent = element.fromTerminalToDoor;
    resultsFootnoteItemDescrEurope.textContent = element.closestTerminal;
    resultsFootnoteDateItemTextDaysShippingEurope.textContent = element.shippingToPoland;
    resultsFootnoteDateItemTextDaysArrivedEurope.textContent = element.arrivedToPoland;
    currentEuropeResultsForModal.from = element.id;
    currentEuropeResultsForModal.To = checkFromEuropeDestinationBtn.textContent;
  };

  var makeResultForFastTrains = function makeResultForFastTrains(element) {
    profitableLocationTxtDynamic.textContent = element.profitableLocation;
    chinaResultFromStationToStation.textContent = element.fromStationToStation;
    chinaResultFromTerminalToTerminalBig.textContent = element.fromTerminalToDoorBig;
    chinaResultFromTerminalToTerminalSmall.textContent = element.fromTerminalToDoorSmall;
    chinaResultsFootnoteItemTitle.textContent = element.terminalLocation;
    chinaResultsFootnoteItemDescr.textContent = element.closestTerminal;
    chinaResultsFootnoteDateItemText.textContent = element.cutOff;
    currentChinaResultsForModal.from = element.id; // currentChinaResultsForModal.from = checkOnFastTraineBtn.textContent;
  };

  var makeResultsForServiceTable = function makeResultsForServiceTable(element) {
    // console.log(element);
    serviceResultFromTerminalToDoor.textContent = element.postFromTerminallToDoorBig;
    serviceResultFromTerminalToDoorSmall.textContent = element.postFromTerminallToDoorSmall;
    serviceResultFromDoorToDoor.textContent = element.postFromDoorlToDoorBig;
    serviceResultFromDoorToDoorSmall.textContent = element.postFromDoorlToDoorSmall;
    serviceContainerResultFromTerminalToDoor.textContent = element.containerFromTerminalToDoor;
    serviceContainerResultFromDoorToDoor.textContent = element.containerFromDorToDoor;
    serviceResultsFootnoteItemTitle.textContent = element.terminalLocation.toUpperCase();
    serviceResultsFootnoteItemDescr.textContent = element.closestTerminal;
    serviceResultsFootnoteDateItemText.textContent = element.cutOff;
    serviceShipResultsFootnoteDateItemText.textContent = element.shipOff;
    currentServeceResultsForModal.from = element.id;
  };

  var findElemetIdOnClick = function findElemetIdOnClick(currentDataArr, callback) {
    // console.log('ckick');
    var target = event.target;
    var currentCheldrenlist = target.nextElementSibling.childNodes;
    currentCheldrenlist.forEach(function (item) {
      item.addEventListener("click", function () {
        target.textContent = item.textContent;
        var currentobjectData = currentDataArr.find(function (el) {
          return el.id == item.textContent;
        });
        callback(currentobjectData);
      });
    });
  }; ///listeners for inputs


  var creatCurrentEuropeRes = function creatCurrentEuropeRes(currentobjectData) {
    makeResultForEuropeTable(currentobjectData);
    resetToZeroCurrentCalendar(europeCalendarData);
    madeStartValForCurrentPointsData(europeDataOrangePoint, true);
    createDecoratedCalendar(europeCalendarData, false);
  };

  var creatCurrentChinaRes = function creatCurrentChinaRes(currentobjectData) {
    // console.log(chinaCalendarsData);
    resetToZeroCurrentCalendar(chinaCalendarsData);
    chinaCalendarsData.shippingDates = currentobjectData.shippingDates;
    chinaCalendarsData.goodDeliveryDeadline = currentobjectData.goodDeliveryDeadline;
    madeStartValForCurrentPointsData(chinaDataOrangePoint);
    makeResultForFastTrains(currentobjectData);
    createDecoratedCalendar(chinaCalendarsData, false);
  };

  var createCurrentServiceDataRes = function createCurrentServiceDataRes(currentobjectData) {
    makeResultsForServiceTable(currentobjectData);

    resetToZeroCurrentCalendar(serviceCalendarData);
    madeStartValForCurrentPointsData(serviceDataOrangePoint);
  };

  var madeStartValForCurrentPointsData = function madeStartValForCurrentPointsData(pointData, weekend) {
    pointData.year = date.getFullYear();
    pointData.month = date.getMonth();
    pointData.day = date.getDate() + 1;

    if (weekend !== undefined) {
      var currentDate = new Date();
      console.log(currentDate.getDay());

      if (currentDate.getDay() == 5) {
        pointData.day = date.getDate() + 3;
      }

      if (currentDate.getDay() == 2) {
        pointData.day = date.getDate() + 2;
      }
    }
  };

  //// checkboxes of china calendars
  var calendarChoiseInputs = document.querySelectorAll(".calendar-choise-input-js");
  var onFastTrainInput = document.querySelector("#on-fast-traine");
  var seaPlusTraineInput = document.querySelector("#sea-plus-traine"); //////btns

  var checkFromEuropeCountryBtn = document.querySelector(".check-from-europe-country-btn-js");
  var checkFromEuropeDestinationBtn = document.querySelector(".check-from-europe-destination-btn-js");
  var checkOnFastTraineBtn = document.querySelector(".check-on-fast-traine-js"); ////////buttons of request

  var calendarRequestBtnEurope = document.querySelector(".calendar-request-btn-europe-js");
  var calendarRequestBtnChina = document.querySelector(".calendar-request-btn-china-js");
  deliverySheduleOnLoad();
  calendarChoiseInputs.forEach(function (element) {
    if (element.hasAttribute("checked", "checked")) {
      loadGroupCargoById(element.id);
    }
  });
  createEuropeDestObj(inputForMsk);
  getJsonDataForDeliverySchedule(deliveryScheduleEuropeCalandarDataPath).then(creatEuropeResObj);
  getJsonDataForDeliverySchedule(deliverySheduleCommonCalendarDataBasePath).then(creatCurrentEuropeCalendars);
  checkFromEuropeCountryBtn.addEventListener("click", function () {
    findElemetIdOnClick(selectFromEuropeDataArr, creatCurrentEuropeRes);
  });
  checkFromEuropeDestinationBtn.addEventListener('click', function () {
    var target = event.target;
    target.nextElementSibling.addEventListener('click', function () {
      target.classList.remove('open-btn');
      target.nextElementSibling.classList.remove('show');
    });
  });
  checkOnFastTraineBtn.addEventListener("click", function () {
    findElemetIdOnClick(selectFromFasttrainsChinaArr, creatCurrentChinaRes);
  });
  checkServiceBtn.addEventListener("click", function () {
    findElemetIdOnClick(selectFromServiceCalendarDataArr, createCurrentServiceDataRes);
  });
  calendarRequestBtnEurope.addEventListener("click", showDeliveryScheduleModal.bind(null, event, currentEuropeResultsForModal, europeDataOrangePoint, false, "\u0438\u0437 \u0415\u0432\u0440\u043E\u043F\u044B"));
  calendarRequestBtnChina.addEventListener("click", showDeliveryScheduleModal.bind(null, event, currentChinaResultsForModal, chinaDataOrangePoint, false, "\u0438\u0437 \u041A\u0438\u0442\u0430\u044F (\u043F\u0440\u044F\u043C\u043E\u0435 \u0436\u0434)"));
  serviceCalendarRequestBtn.addEventListener('click', showDeliveryScheduleModal.bind(null, event, currentServeceResultsForModal, serviceDataOrangePoint, true, "\u0438\u0437 \u041A\u0438\u0442\u0430\u044F (\u043C\u043E\u0440\u0435 + \u0436\u0434)"));
} ///// reset calendars on btn change


function resetToZeroCurrentCalendar(clanedarData) {
  clanedarData.currentYear = date.getFullYear();
  clanedarData.currentMonth = date.getMonth();
  clanedarData.curentData = date.getDate();
  return;
} // function for changes current month


function loadMonth(event, num, domElement, isTodayLight) {
  domElement.currentMonth = domElement.currentMonth + num;
  checkMonthNum(domElement);

  if (isTodayLight == true) {
    createDecoratedCalendar(domElement, true);
  } else {
    createDecoratedCalendar(domElement);
  } // creatDecoratedCalendar(domElement);

}

function checkMonthNum(domElement) {
  if (domElement.currentMonth < 0) {
    domElement.currentMonth = 11;
    domElement.currentYear = domElement.currentYear - 1;
  }

  if (domElement.currentMonth > 11) {
    domElement.currentMonth = 0;
    domElement.currentYear = domElement.currentYear + 1;
  }
} ///validatrion amount of numbers in modal


function validation(input) {
  input.value = input.value.replace(/[^\d,]/g, "");
} ///////////-------------------------------COMMMOM FUNCTIONS FOR ALL PAGES WITH CALENDARS


function getJsonDataForDeliverySchedule(path) {
  return fetch(path).then(function (response) {
    if (response.ok) return response.json();
    throw new Error("Error fetching data");
  }).catch(function (error) {
    console.error("Error: ", error);
  });
}

function createServiceCalendarRes(data) {
  // console.log(data);
  selectFromServiceCalendarDataArr = data;

  if (checkServiceBtn) {
    createCurrentInput(selectFromServiceCalendarDataArr, checkServiceBtn, makeResultsForServiceTable);
  }
}

function createCurrentInput(dataArr, input, callBack) {
  var startElement = dataArr[0]; // console.log(startElement);

  input.textContent = startElement.id;

  for (var key in dataArr) {
    dropDownList += "<li class=\"dropdown-list-item dropdown-list-item--calendar\">".concat(dataArr[key].id, "</li>");
  }

  input.nextElementSibling.innerHTML = dropDownList;
  dropDownList = "";

  if (callBack) {
    callBack(startElement);
  }
}

function makeStartForCalendars(curentData, pathOnDOM, monthNameOnDom, yearNameOnDOM, isTodayLight) {
  curentData.currentYear = date.getFullYear();
  curentData.currentMonth = date.getMonth();
  curentData.currentDay = date.getDate();
  curentData.path = document.querySelector(pathOnDOM);
  curentData.monthName = document.querySelector(monthNameOnDom);
  curentData.yearName = document.querySelector(yearNameOnDOM); // console.log(curentData);

  createDecoratedCalendar(curentData, isTodayLight);
  return curentData;
}

function createDecoratedCalendar(domElement, isTodayLight) {
  // console.log(domElement);
  function createCalendar(domElement) {
    domElement.monthName.textContent = monthNameArr[domElement.currentMonth];
    domElement.yearName.textContent = domElement.currentYear;
    var d = new Date(domElement.currentYear, domElement.currentMonth);
    var table = ""; // add empty boxes from monday to first date

    for (var i = 0; i < getDay(d); i++) {
      table += '<li class="calendar__dates-item calendar-dates-item-js"></li>';
    } // add boxes with date


    while (d.getMonth() == domElement.currentMonth) {
      table += '<li class="calendar__dates-item calendar-dates-item-js">' + d.getDate() + "</li>";
      d.setDate(d.getDate() + 1);
    } // add empty boxes


    if (getDay(d) != 0) {
      for (var _i = getDay(d); _i < 7; _i++) {
        table += '<li class="calendar__dates-item calendar-dates-item-js"></li>';
      }
    }

    domElement.path.innerHTML = table;
  } /// get days from 0 to 6


  function getDay(date) {
    var day = date.getDay();
    if (day == 0) day = 7;
    return day - 1;
  }

  createCalendar(domElement);
  var currentChilNodesArr = Array.from(domElement.path.childNodes);

  function makeActiveDates(domElement) {
    function makeColorsDays(item, isActive) {
      if (isActive == true) {
        item.classList.remove(domElement.notActiveColorText);
        item.classList.add(domElement.activeColorText);
      } else {
        item.classList.remove(domElement.activeColorText);
        item.classList.add(domElement.notActiveColorText);
      }
    }

    if (domElement.currentYear < date.getFullYear() || domElement.currentYear <= date.getFullYear() && domElement.currentMonth < date.getMonth()) {
      currentChilNodesArr.map(function (item) {
        makeColorsDays(item, false);
      });
      return;
    } else {
      if (domElement.currentYear > date.getFullYear() || domElement.currentYear >= date.getFullYear() && domElement.currentMonth > date.getMonth()) {
        currentChilNodesArr.map(function (item) {
          makeColorsDays(item, true);
        });
        return;
      } else {
        currentChilNodesArr.map(function (item) {
          if (+item.textContent < date.getDate() && domElement.currentYear == date.getFullYear() && domElement.currentMonth == date.getMonth()) {
            makeColorsDays(item, false);
            return;
          }

          if (+item.textContent >= date.getDate() && domElement.currentYear == date.getFullYear() && domElement.currentMonth == date.getMonth()) {
            makeColorsDays(item, true);
            return;
          }
        });
      }
    }
  }

  function makePointsForShippingDays(domElement, currentChilNodesArr, currentDatesArr, active, notActive) {
    // console.log(currentChilNodesArr);
    currentDatesArr.map(function (shipElement) {
      var variableIndex = shipElement;
      currentChilNodesArr.map(function (element) {
        if (currentChilNodesArr.indexOf(element) == variableIndex) {
          if (element.textContent !== "") {
            if (domElement.currentYear < date.getFullYear() || domElement.currentYear <= date.getFullYear() && domElement.currentMonth < date.getMonth()) {
              element.classList.remove(active);
              element.classList.add(notActive);
            }

            if (domElement.currentYear > date.getFullYear() || domElement.currentYear >= date.getFullYear() && domElement.currentMonth > date.getMonth()) {
              element.classList.remove(notActive);
              element.classList.add(active);
            } else {
              if (+element.textContent < date.getDate() && domElement.currentYear == date.getFullYear() && domElement.currentMonth == date.getMonth()) {
                element.classList.remove(active);
                element.classList.add(notActive);
              }

              if (+element.textContent >= date.getDate() && domElement.currentYear == date.getFullYear() && domElement.currentMonth == date.getMonth()) {
                element.classList.remove(notActive);
                element.classList.add(active);
              }
            }
          }

          if (shipElement > 6) {
            variableIndex += 14;
          } else {
            variableIndex += 7;
          }
        }
      });
    });
  } // function makeDeadlineDays(){
  //   if(domElement.goodDeliveryDeadline){
  //   domElement.goodDeliveryDeadline.map(deadLineElement=>{
  //     let variableIndex = deadLineElement;
  //     currentChilNodesArr.map(element=>{
  //       if (element.textContent !== "") {
  //       }
  //     })
  //   })
  //   }
  // }


  function makeActiveDatesForUser(domElement, currentChilNodesArr, isTodayLight) {
    if (domElement.currentYear < date.getFullYear() || domElement.currentYear <= date.getFullYear() && domElement.currentMonth < date.getMonth()) {
      return;
    }

    if (domElement.currentYear > date.getFullYear() || domElement.currentMonth > date.getMonth() && domElement.currentYear >= date.getFullYear()) {
      currentChilNodesArr.map(function (element) {
        element.classList.add("hover-active-pointer");
      });
    } else {
      currentChilNodesArr.map(function (element) {
        if (element.classList.contains(domElement.activeColorText)) {
          // console.log(isTodayLight);
          if (isTodayLight) {
            if (+element.textContent == date.getDate()) {
              element.classList.add("hover-active-pointer");
            }
          }

          if (+element.textContent > date.getDate()) {
            element.classList.add("hover-active-pointer");
          }
        }
      });
    }

    if (!domElement.weekendIsActive) {
      var weekEndArr = [5, 6];
      weekEndArr.map(function (element) {
        var weekEndCounter = element;
        currentChilNodesArr.map(function (subElement) {
          if (currentChilNodesArr.indexOf(subElement) == weekEndCounter) {
            subElement.classList.remove("hover-active-pointer");
            weekEndCounter += 7;
          }
        });
      });
    }
  }

  function makeOrangePointOnStart(domElement, currentChilNodesArr, currentYear, currentMonth, isTodayLight) {
    // console.log(isTodayLight);
    // console.log(serviceDataOrangePoint);
    if (domElement.calendarName == "europeCalendar") {
      writeOrangePointposition(europeDataOrangePoint); // console.log(domElement);
    }

    if (domElement.calendarName == "chinaCalendar") {
      writeOrangePointposition(chinaDataOrangePoint);
    }

    if (domElement.calendarName == "serviceCalendar") {
      writeOrangePointposition(serviceDataOrangePoint);
    }

    function setNetElementTodayLight(nextElement) {
      // console.log(nextElement)
      if (isTodayLight) {
        if (nextElement.previousElementSibling.classList.contains("hover-active-pointer")) {
          nextElement.previousElementSibling.classList.add("today-light");
        } else {
          nextElement.classList.add("today-light");
        } // console.log('srrt');


        return;
      }

      if (nextElement.classList.contains("hover-active-pointer")) {
        nextElement.classList.add("today-light");
      } else {
        nextElement += 1; // console.log(nextElement)     

        if (nextElement.classList.contains("hover-active-pointer")) {
          nextElement.classList.add("today-light");
        } else {
          // console.log(nextElement)
          // nextElement += 1;
          nextElement.nextElementSibling.classList.add("today-light");
        }
      }
    } /////////write data of positin orange point


    function writeOrangePointposition(pointDataObj) {
      if (Object.keys(pointDataObj).length == 0) {
        var activeCurrentDays = currentChilNodesArr.filter(function (element) {
          return element.classList.contains("hover-active-pointer");
        }).find(function (element) {
          return element.textContent > date.getDate();
        });
        var PointNetxElement = activeCurrentDays; ////// set today ligth for calendar on current month

        if (PointNetxElement) {
          setNetElementTodayLight(PointNetxElement);
          newOrangePoint(domElement, currentChilNodesArr);
        } else {
          newOrangePoint(domElement, currentChilNodesArr);
        }
      } else {
        if (domElement.currentYear == pointDataObj.year && domElement.currentMonth == pointDataObj.month) {
          var findOrangePosition = currentChilNodesArr.filter(function (element) {
            return element.classList.contains("hover-active-pointer");
          }).find(function (element) {
            return element.textContent >= pointDataObj.day;
          });

          if (findOrangePosition) {
            setNetElementTodayLight(findOrangePosition);
            newOrangePoint(domElement, currentChilNodesArr);
          } else {
            newOrangePoint(domElement, currentChilNodesArr);
          }
        }
      }
    }
  }

  function listenerForUserClick(domElement, currentChilNodesArr, isTodayLight) {
    currentChilNodesArr.forEach(function (element) {
      element.addEventListener("click", function () {
        if (element.classList.contains("hover-active-pointer")) {
          var mekeTodayLightOnClick = function mekeTodayLightOnClick() {
            currentChilNodesArr.map(function (element) {
              element.classList.remove("today-light");
            });
            element.classList.add("today-light");
            newOrangePoint(domElement, currentChilNodesArr);
          };

          if (domElement.currentYear == date.getFullYear() && domElement.currentMonth == date.getMonth() && element.textContent == date.getDate()) {
            if (isTodayLight) {
              mekeTodayLightOnClick();
            }

            return;
          }

          mekeTodayLightOnClick();
        }
      });
    });
  }

  function newOrangePoint(domElement, currentChilNodesArr) {
    if (domElement.calendarName == "europeCalendar") {
      setOrangePoints(europeDataOrangePoint); // console.log(europeDataOrangePoint);
    }

    if (domElement.calendarName == "chinaCalendar") {
      setOrangePoints(chinaDataOrangePoint);
    }

    if (domElement.calendarName == "serviceCalendar") {
      setOrangePoints(serviceDataOrangePoint); // console.log(serviceDataOrangePoint);
    } /////set data for new orange points


    function setOrangePoints(cuurentPointsObj) {
      // console.log(cuurentPointsObj);
      cuurentPointsObj.year = domElement.currentYear;
      cuurentPointsObj.month = domElement.currentMonth; // console.log(date.getMonth());
      ////last chenge

      if (cuurentPointsObj.year == date.getFullYear() && cuurentPointsObj.month == date.getMonth()) {
        // console.log('hhjhy');
        // console.log(currentChilNodesArr.find(element =>element.classList.contains("today-light")));
        if (currentChilNodesArr.find(function (element) {
          return element.classList.contains("today-light");
        })) {
          cuurentPointsObj.day = +currentChilNodesArr.find(function (element) {
            return element.classList.contains("today-light");
          }).textContent;
        } else {
          // console.log('else orange point');
          cuurentPointsObj.month += 1;

          if (cuurentPointsObj.month > 11) {
            cuurentPointsObj.month = 0;
            cuurentPointsObj.year += 1;
          }

          cuurentPointsObj.day = 1;
          loadMonth(event, 1, domElement);
          loadMonth(event, -1, domElement);
        }
      } else {
        cuurentPointsObj.day = +currentChilNodesArr.find(function (element) {
          return element.classList.contains("today-light");
        }).textContent;
      }
    }
  }

  makeActiveDates(domElement);

  if (domElement.shippingDates) {
    makePointsForShippingDays(domElement, currentChilNodesArr, domElement.shippingDates, domElement.shippingDatesActive, domElement.shippingDatesNotActive);
  } // console.log(domElement.goodDeliveryDeadline)


  if (domElement.goodDeliveryDeadline) {
    makePointsForShippingDays(domElement, currentChilNodesArr, domElement.goodDeliveryDeadline, domElement.shippingDatesDeadline, domElement.shippingDatesDeadlineNotActive);
  }

  makeActiveDatesForUser(domElement, currentChilNodesArr, isTodayLight);
  makeOrangePointOnStart(domElement, currentChilNodesArr, domElement.currentYear, domElement.currentMonth, isTodayLight);
  listenerForUserClick(domElement, currentChilNodesArr, isTodayLight);
}

function loadServiceCalendarDataCurrenrtButtonDay(data) {
  if (calendarChoiceBtnsText) {
    calendarChoiceBtnsText.textContent = makeServiceCalendarActuallyDate();
  }

  if (supportFormInputDeliveryRequest) {
    // console.log(supportFormInputDeliveryRequest);
    supportFormInputDeliveryRequest.value = makeServiceCalendarActuallyDate();
  }

  function makeServiceCalendarActuallyDate() {
    var monthNum = data.currentMonth;
    var currentText = "".concat(checkForNum(data.currentDay), ".").concat(checkForNum(monthNum + 1), ".").concat(data.currentYear);
    return currentText;
  }

  function checkForNum(num) {
    if (num < 10) {
      return "0" + num;
    }

    return num;
  }
} ////////////////////////////////////////////////////////////////////Functions for hide title descr


var internationalTransportationDescriptionTitleDeliverySchedule = document.querySelector('.international-transportation-description-title-delivery-schedule-js');
var decorationRectangleDeliverySchedule = document.querySelector('.decoration-rectangle-delivery-schedule-js');

if (internationalTransportationDescriptionTitleDeliverySchedule) {
  showOrHideinternationalTransportationDescriptionTitleDeliverySchedule(window.innerWidth);
  window.addEventListener('resize', function () {
    showOrHideinternationalTransportationDescriptionTitleDeliverySchedule(window.innerWidth);
  });
}

function showOrHideinternationalTransportationDescriptionTitleDeliverySchedule(width) {
  if (width <= 768) {
    internationalTransportationDescriptionTitleDeliverySchedule.classList.add('visually-hidden');
    decorationRectangleDeliverySchedule.classList.add('visually-hidden');
  } else {
    internationalTransportationDescriptionTitleDeliverySchedule.classList.remove('visually-hidden');
    decorationRectangleDeliverySchedule.classList.remove('visually-hidden');
  }
} ///////function for sending letters


function sendDataFromDeliverySchedule() {
  //  console.log(currentDestFirstName);
  var modalDeliveryScheduleRoute = document.getElementById('modal-delivery-schedule-route');
  var modalDeliveryScheduleCurrentDate = document.getElementById('modal-delivery-schedule-current-date');
  var modalDeliveryScheduleResultAdressInput = document.getElementById('modal-delivery-schedule-result-adress-input');
  var takeFromDoorRadioButton = document.getElementById('take-from-door');
  var modalDeliveryScheduleResultFirstAdressInput = document.getElementById('modal-delivery-schedule-result-first-adress-input');
  var modalDeliverySchedulUsersDataDestTextFromUser = document.querySelector('.modal-delivery-schedul-users-data-dest-text-from-user-js');
  var modalDeliveryScheduleUsersDataDateTextFromUser = document.querySelector('.modal-delivery-schedule-users-data-date-text-from-user-js');
  var modalDeliverySchedulLoadFromUserAdressInput = document.querySelector('.modal-delivery-schedul-load-from-user-adress-input-js');
  var thankYouPopUpWrapper = document.querySelector('.thank-you-pop-up-wrapper-js');
  modalDeliveryScheduleRoute.value = modalDeliverySchedulUsersDataDestTextFromUser.textContent; //  console.log( modalDeliveryScheduleRoute.value);

  modalDeliveryScheduleCurrentDate.value = modalDeliveryScheduleUsersDataDateTextFromUser.textContent; //  console.log( modalDeliveryScheduleCurrentDate.value);

  modalDeliveryScheduleResultFirstAdressInput.value = currentDestFirstName;
  console.log(modalDeliveryScheduleResultFirstAdressInput.value); // return;

  if (modalDeliverySchedulLoadFromUserAdressInput.value !== '') {
    modalDeliveryScheduleResultAdressInput.value = modalDeliverySchedulLoadFromUserAdressInput.value;
  } else if (takeFromDoorRadioButton.nextElementSibling.classList.contains('delivery-modal-label--active')) {
    modalDeliveryScheduleResultAdressInput.value = 'Не указан';
  } else {
    modalDeliveryScheduleResultAdressInput.value = 'Терминал ТРАНСПОРТИР';
  } //  console.log(modalDeliveryScheduleResultAdressInput.value);


  thankYouPopUpWrapper.classList.add('thank-you-pop-up__wrapper--bind-to-modal-calc');
  showThankUpopUp('.submit-application-delivery-schedule-js');
  return;
}