"use strict";

var sliderList = document.querySelector(".slider-list-js");
var sliderItemFirst = document.querySelector(".slider-item-first-js");
var sliderLogisticList = document.querySelector('.slider-logistic-list-js');
var sliderItemLogisticFirst = document.querySelector('.slider-item-logistic-first-js');
var moreServicesBodyControlsLogisticSlider = document.querySelector('.more-services-body-controls-logistic-slider-js');

if (moreServicesBodyControlsLogisticSlider) {
  var swiperContainerLogistic = document.querySelector('.swiper-container--logistic');
  var swiperContainerMoreServices = document.querySelector('.swiper-container--more-services');

  if (swiperContainerMoreServices) {
    var swiperContainerMoreServicesWrapper = swiperContainerMoreServices.querySelector('.swiper-container--more-services-wrapper');
    var swiperSlideMoreServicesList = swiperContainerMoreServices.querySelectorAll('.swiper-slide--more-services');
    swiperContainerMoreServices.classList.add('swiper-container');
    swiperContainerMoreServicesWrapper.classList.add('swiper-wrapper');
    swiperSlideMoreServicesList.forEach(function (item) {
      item.classList.add('swiper-slide');
    });
    var indexSwiperLogistic = new Swiper('.swiper-container', {
      slidesPerView: 3,
      noSwiping: true,
      freeMode: true,
      navigation: {
        nextEl: '.swiper-button-next--doc-slider',
        prevEl: '.swiper-button-prev--doc-slider'
      },
      breakpoints: {
        1024: {
          slidesPerView: 2
        } // 768:{
        //   slidesPerView:2,        
        // },
        // 480:{
        //   slidesPerView:2,             
        // }

      }
    });
  }
}

var integratedLogisticsSlider = document.querySelector('.integrated-logistics-slider');
// console.log(integratedLogisticsSlider);

if (integratedLogisticsSlider) {
  var wrapperSliderIntegratedLogistic = integratedLogisticsSlider.querySelector('.wrapper-slider--integrated-logistic');
  var sliderlogisticlist = integratedLogisticsSlider.querySelector('.slider-logistic-list-js');
  // console.log(sliderlogisticlist);
  var sliderItemsLogistic = integratedLogisticsSlider.querySelectorAll('.slider-item');
  if( wrapperSliderIntegratedLogistic){
    wrapperSliderIntegratedLogistic.classList.add('swiper-container-logistic');
    sliderlogisticlist.classList.add('swiper-wrapper');
  sliderItemsLogistic.forEach(function (item) {
    item.classList.add('swiper-slide');
  });
  }
 
  
if(sliderlogisticlist){
  if (sliderItemsLogistic.length <= 3) {
    document.querySelector('.more-services-body-controls-logistic-slider-js').style.display = 'none';

    if (window.innerWidth > 768) {
      sliderItemsLogistic.forEach(function (item) {
        item.classList.add('swiper-no-swiping');
      });
    }
  }
}


  var integratedLogisticSlider = new Swiper('.swiper-container-logistic', {
    slidesPerView: 2,
    noSwipe: true,
    freeMode: true,
    navigation: {
      nextEl: '.swiper-button-next--doc-slider--first',
      prevEl: '.swiper-button-prev--doc-slider--first'
    },
    breakpoints: {
      768: {
        slidesPerView: 2
      },
      480: {
        slidesPerView: 2
      }
    }
  });
}