"use strict";

var modalTableInsideBgcWrapper = document.querySelector('.modal-table-inside-bgc-wrapper-js');

if (modalTableInsideBgcWrapper) {
  var priceModalItemsList = document.querySelectorAll('.price-modal-item-js');
  var supportFormSubmitGrouppageCargoFromEuropeBtnsList = document.querySelectorAll('.support-form-submit-grouppage-cargo-from-europe-js'); // priceModalItemsList.forEach(item=>{
  //   item.style.display = 'none';
  // });

  supportFormSubmitGrouppageCargoFromEuropeBtnsList.forEach(function (submitBtn) {
    var headerWrapper = document.querySelector('.header-wrapper');
    var containerSliderImage = document.querySelector('.container-slider-image');
    var mainWrapper = document.querySelector('.main-wrapper');
    var footerWrapper = document.querySelector('.footer-wrapper');
    var currentModalId = submitBtn.getAttribute('href');
    var currentModalTable = document.getElementById(currentModalId);
    var priceModalCloseBtn = currentModalTable.querySelector('.price-modal-close-btn-js');
    var priceModalCloseBtnWrapper = currentModalTable.querySelector('.price-modal-close-btn-wrapper-js');
    submitBtn.addEventListener('click', function () {
      var pageOfSetForResultTableShowing = window.scrollY; // console.log(pageOfSetForResultTableShowing);    

      var siteWidth = 1280;
      var scale; // const doc = window.innerWidth;

      var currentInnerWidthForResultTable = window.innerWidth; // console.log(doc);

      if (siteWidth > window.innerWidth) {
        scale = window.innerWidth / siteWidth;
        document.querySelector('meta[name="viewport"]').setAttribute('content', "width=".concat(siteWidth, ", initial-scale=").concat(scale));
         // document.querySelector('body').style.width = '1280px';

        priceModalCloseBtnWrapper.style.width = '60px';
        priceModalCloseBtnWrapper.style.height = '60px';
      }

      modalTableInsideBgcWrapper.style.display = 'block';
      currentModalTable.style.display = 'block';
      headerWrapper.style.display = 'none';
      containerSliderImage.style.display = 'none';
      mainWrapper.style.display = 'none';
      footerWrapper.style.display = 'none';
      window.scrollTo(0, 0); // document.querySelector('body').classList.add('stop-scroll'); 
      // document.querySelector('html').classList.add('stop-scroll'); 

      priceModalCloseBtn.addEventListener('click', function () {
        // console.log(window.innerWidth);
        // console.log(document.body.clientWidth);
        document.querySelector('meta[name="viewport"]').setAttribute('content', "width=device-width, initial-scale=1.0");
        modalTableInsideBgcWrapper.style.display = 'none';
        currentModalTable.style.display = 'none';
        headerWrapper.style.display = 'block';
        containerSliderImage.style.display = 'block';
        mainWrapper.style.display = 'block';
        footerWrapper.style.display = 'block'; // console.log(window.innerWidth);

        window.innerWidth = currentInnerWidthForResultTable;
        makeCurrentHeaderTpl(); // console.log('fff');;
        // console.log(document.body.clientWidth);

        // priceModalCloseBtnWrapper.style.width = '35px';
        // priceModalCloseBtnWrapper.style.height = '35px';
        window.scrollTo(0, pageOfSetForResultTableShowing);
      });
    });
  });
}