"use strict";

var bestAviaRatesResultsWrappersList = document.querySelectorAll('.best-avia-rates-results-wrapper-js');
var bestAviaRatesTableUsersDeparturePointLinksList = document.querySelectorAll('.best-avia-rates-table-users-departure-point-link-js');
var bestAviaRatesTableUsersStockroomLinksList = document.querySelectorAll('.best-avia-rates-table-users-stockroom-link-js');

if (bestAviaRatesResultsWrappersList.length !==0) {
  var currentStockroomLinkId = bestAviaRatesTableUsersStockroomLinksList[0].getAttribute('id');
  var currentStockroomLinkIdLength = currentStockroomLinkId.length;
  var currentPointLinksArray = [];
  bestAviaRatesResultsWrappersList.forEach(function (listItem) {
    // listItem.classList.remove('best-avia-rates__results-wrapper--active');
    if (listItem.getAttribute('id').slice(0, currentStockroomLinkIdLength) == currentStockroomLinkId) {
      currentPointLinksArray.push(listItem);
    }
  });
  bestAviaRatesTableUsersStockroomLinksList.forEach(function (StockroomLink) {
    ///// find current StockroomLink on click
    StockroomLink.addEventListener('click', function () {
      bestAviaRatesTableUsersStockroomLinksList.forEach(function (item) {
        ////remove all bottom borders on StockroomLinks
        item.classList.remove("link--active-border");
      }); ///// set current StockroomLink bottom border

      StockroomLink.classList.add("link--active-border"); ////remove all bottom borders on PointLinks

      bestAviaRatesTableUsersDeparturePointLinksList.forEach(function (PointLink) {
        PointLink.classList.remove("link--active-border-color");
      }); ///// set to first PointLinks bottom border

      bestAviaRatesTableUsersDeparturePointLinksList[0].classList.add("link--active-border-color"); ////// create current PointLinks active array

      currentStockroomLinkId = StockroomLink.getAttribute('id');
      currentStockroomLinkIdLength = currentStockroomLinkId.length;
      currentPointLinksArray = [];
      bestAviaRatesResultsWrappersList.forEach(function (listItem) {
        listItem.classList.remove('best-avia-rates__results-wrapper--active');

        if (listItem.getAttribute('id').slice(0, currentStockroomLinkIdLength) == currentStockroomLinkId) {
          currentPointLinksArray.push(listItem);
        }
      });
      currentPointLinksArray[0].classList.add('best-avia-rates__results-wrapper--active'); /////find PointLink on click
    });
    bestAviaRatesTableUsersDeparturePointLinksList.forEach(function (link) {
      link.addEventListener('click', function () {
        bestAviaRatesTableUsersDeparturePointLinksList.forEach(function (linkItem) {
          linkItem.classList.remove('link--active-border-color');
        });
        link.classList.add('link--active-border-color');
        var currentPointlinkId = link.getAttribute('id');
        var currenResultTableId = currentStockroomLinkId + '-' + currentPointlinkId;
        bestAviaRatesResultsWrappersList.forEach(function (item) {
          item.classList.remove('best-avia-rates__results-wrapper--active');
        });
        document.getElementById(currenResultTableId).classList.add('best-avia-rates__results-wrapper--active');
      });
    });
  });
}