"use strict";

var onlineRequestFormForCarries = document.querySelector('.online-request-form-for-carries-js');

if (onlineRequestFormForCarries) {
  var forCarriesFormSubmit = function forCarriesFormSubmit() {
    var adr = document.getElementById('adr');
    var ekmt = document.getElementById('ekmt');

    function isInputChecked(currentInputCheckbox) {
      if (currentInputCheckbox.checked) {
        currentInputCheckbox.value = 'Отмечено';
      } else {
        currentInputCheckbox.value = 'Не отмечено';
      }
    }

    isInputChecked(adr);
    isInputChecked(ekmt);

    if (window.innerWidth <= 480) {
      showThankUpopUp('.support-form-gray-js');
    } else {
      showThankUpopUp('.online-request-form-js');
    }
  };

  ////// get all divs with lables
  var onlineRequestFormForCarriesinputRequiredList = onlineRequestFormForCarries.querySelectorAll('.input-required-js'); /// hide decorated lable on click

  onlineRequestFormForCarriesinputRequiredList.forEach(function (inputItem) {
    inputItem.addEventListener('click', function () {
      inputItem.firstElementChild.classList.add('hide');
    });
    inputItem.querySelector('input').addEventListener('focus', function () {
      inputItem.firstElementChild.classList.add('hide');
    });
    inputItem.querySelector('input').addEventListener('blur', function () {
      if (!inputItem.querySelector('input').value) {
        inputItem.firstElementChild.classList.remove('hide');
      }
    });
  });
}