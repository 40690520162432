"use strict";

var infoCircleOnlineRequest = document.querySelector(".info-circle-online-request-js");
var infoCircleServiceCalendar = document.querySelector('.info-circle-service-calendar-js');
var infoCircleModalDeliveryShedule = document.querySelector('.info-circle-modal-delivery-shedule-js');
var infoCircleCalculator = document.querySelector('.info-circle-calculator-js');
var infoCircleResultTableInfo = document.querySelectorAll('.info-circle-result-table-info-js');

if (infoCircleOnlineRequest) {
  var infoCircleTextOnlineRequest = document.querySelector(".info-circle-text-online-request-js");
  var infoCircleNameplateOnlineRequest = document.querySelector(".info-circle-nameplate-online-request-js");
  infoCircleOnlineRequest.addEventListener('click', function (event){
    infoCircleHeadler(event, infoCircleOnlineRequest, infoCircleNameplateOnlineRequest, infoCircleTextOnlineRequest, '#fff');
  })
  

}

if (infoCircleServiceCalendar) {
  var infoCircleServiveCalendarNameplate = document.querySelector('.info-circle-servive-calendar-nameplate-js');
  infoCircleServiceCalendar.addEventListener('click', function(event){
    infoCircleHeadler(event,  infoCircleServiceCalendar, infoCircleServiveCalendarNameplate, false, '#fff');
  }) 

}

if (infoCircleModalDeliveryShedule) {
  var infoCircleNameplateModalDeliverySchedule = document.querySelector('.info-circle-nameplate-modal-delivery-schedule-js');
  infoCircleModalDeliveryShedule.addEventListener('click', function(event){
    infoCircleHeadler(event, infoCircleModalDeliveryShedule, infoCircleNameplateModalDeliverySchedule, false, '#fff');
  })
  
}

if (infoCircleCalculator) {
  var tooltipCalculator = document.querySelector('.tooltip-calculator-js');
  infoCircleCalculator.addEventListener('click', function(event){
    infoCircleHeadler(event, infoCircleCalculator, tooltipCalculator, false, '#fff');
  })
}

if (infoCircleResultTableInfo) {
  // const infoCircleNameplateResultTableInfo =  document.querySelector('.info-circle-nameplate-result-table-info-js');
  infoCircleResultTableInfo.forEach(function (circle) {
   var infoCircleNameplateResultTableInfo = circle.querySelector('.info-circle-nameplate-result-table-info-js');
    circle.addEventListener('click', function (event){    
      infoCircleHeadler(event,circle, infoCircleNameplateResultTableInfo, false, '#848997');
    })
    
  });
}

function infoCircleHeadler(event, infoCircle, infoCircleNameplate, infoCircleText, bgc) {
  event.stopImmediatePropagation();

  if (!infoCircle.classList.contains('open-info-circle')) {
    infoCircle.classList.add('open-info-circle');
    infoCircle.style.backgroundColor = '#FE5000';

    if (infoCircleText !== false) {
      infoCircleText.style.color = '#FFFFFF';
    }

    infoCircleNameplate.style.display = 'block';
    window.addEventListener('click', windowInfoCircleHeadler.bind(event, null, infoCircle, infoCircleNameplate, infoCircleText, bgc));
    return;
  } else {
    closeInfoCircleNameplate(infoCircle, infoCircleNameplate, infoCircleText, bgc);
  }
}

function windowInfoCircleHeadler(event, infoCircle, infoCircleNameplate, infoCircleText, bgc) {
  if (infoCircle.classList.contains('open-info-circle')) {
    closeInfoCircleNameplate(infoCircle, infoCircleNameplate, infoCircleText, bgc);
    return;
  } else {
    return;
  }
}

function closeInfoCircleNameplate(infoCircle, infoCircleNameplate, infoCircleText, bgc) {
  infoCircle.classList.remove('open-info-circle');
  infoCircle.style.backgroundColor = bgc;

  if (infoCircleText !== false) {
    infoCircleText.style.color = '#000000';
  }

  infoCircleNameplate.style.display = 'none';
  return;
}