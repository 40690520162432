

// INTEGRATED-LOGISTICS SLIDER 
var carousel2 = document.getElementById('integrated-logistics-carousel');

if (carousel2) {
  var list2 = carousel2.querySelector('ul');
  var listElems2 = carousel2.querySelectorAll('li');
  var integratedLogisticsControls = document.getElementById('integrated-logistics-carousel-controls');
  var position2 = 0;

  if (listElems2.length > 2) {
    integratedLogisticsControls.classList.remove('visually-hidden');
  } else {
    integratedLogisticsControls.classList.add('visually-hidden');
  }

  var cardLogistic = document.querySelector('.card');
  var cardWidth = 289;
  var maxLength = cardWidth * listElems2.length * -1;
  var logisticCarouselControlLeft = document.querySelector('.control-left');
  var logisticCarouselControlRight = document.querySelector('.control-right');

  if (!integratedLogisticsControls.classList.contains('visually-hidden')) {
    logisticCarouselControlRight.addEventListener('click', function () {
      event.preventDefault();
      position2 = position2 - cardWidth;

      if (position2 - cardWidth * 2 < maxLength) {
        position2 = position2 + cardWidth;
        return;
      }

      list2.style.marginLeft = position2 + 'px';
    });
    logisticCarouselControlLeft.addEventListener('click', function () {
      event.preventDefault();
      position2 = position2 + cardWidth;

      if (position2 > 0) {
        position2 = position2 - cardWidth;
        return;
      }

      list2.style.marginLeft = position2 + 'px';
    });
  }

  if (window.innerWidth <= 480) {
    var _cardWidth = 220;
    var maxIntegratedSliderLength = listElems2.length * _cardWidth * -1 + _cardWidth; 
    listElems2.forEach(function (el) {
      el.addEventListener('touchend', function () {
        var clientXVal = event.changedTouches[0].clientX;

        if (clientXVal > 160) {
          position2 = position2 - _cardWidth;

          if (position2 < maxIntegratedSliderLength) {
            position2 = position2 + _cardWidth;
            return;
          }

          list2.style.marginLeft = position2 + 'px';
        }

        if (clientXVal < 160) {
          position2 = position2 + _cardWidth;

          if (position2 > 0) {
            position2 = position2 - _cardWidth;
            return;
          }

          list2.style.marginLeft = position2 + 'px';
        }
      });
    });
  }
}