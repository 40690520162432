/////listeners for all btn inputs
"use strict";

var inputBtns = document.querySelectorAll(".input-btn-js");

if (inputBtns) {
  var btnHeandler = function btnHeandler() {
    event.stopPropagation();
    event.preventDefault();

    if (this.classList.contains("open-btn")) {
      this.classList.remove("open-btn");
      this.nextElementSibling.classList.remove("show");
      return;
    }

    disActiveAllBtns();
    this.classList.add("open-btn");
    this.nextElementSibling.classList.add("show");
  };

  var disActiveAllBtns = function disActiveAllBtns() {
    inputBtns.forEach(function (el) {
      el.classList.remove("open-btn");
      el.nextElementSibling.classList.remove("show");
    });
  };

  window.addEventListener("click", disActiveAllBtns);
  inputBtns.forEach(function (btn) {
    btn.addEventListener("click", btnHeandler);
  });
}
