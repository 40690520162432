var shanghaiInput = document.getElementById('shanghai_input');
var qingdaoInput = document.getElementById('qingdao_input');
var guangzhouInput = document.getElementById('guangzhou_input');
var centralChinaInput = document.getElementById('central_china_input');

var shanghaiLabel = document.getElementById('shanghai_label');
var qingdaoLabel = document.getElementById('qingdao_label');
var guangzhouLabel = document.getElementById('guangzhou_label');
var centralChinaLabel = document.getElementById('central_china_label');

if (shanghaiInput && qingdaoInput && guangzhouInput && centralChinaInput) {
  shanghaiInput.addEventListener('click', function () {
    shanghaiLabel.classList.add('color-blue');

    qingdaoLabel.classList.remove('color-blue');
    guangzhouLabel.classList.remove('color-blue');
    centralChinaLabel.classList.remove('color-blue');
  })

  qingdaoInput.addEventListener('click', function () {
    qingdaoLabel.classList.add('color-blue');

    shanghaiLabel.classList.remove('color-blue');
    guangzhouLabel.classList.remove('color-blue');
    centralChinaLabel.classList.remove('color-blue');
  })

  guangzhouInput.addEventListener('click', function () {
    guangzhouLabel.classList.add('color-blue');

    qingdaoLabel.classList.remove('color-blue');
    shanghaiLabel.classList.remove('color-blue');
    centralChinaLabel.classList.remove('color-blue');
  })

  centralChinaInput.addEventListener('click', function () {
    centralChinaLabel.classList.add('color-blue');

    guangzhouLabel.classList.remove('color-blue');

    qingdaoLabel.classList.remove('color-blue');
    shanghaiLabel.classList.remove('color-blue');
  })
}