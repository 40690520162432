"use strict";

var calculatorContainer = document.querySelector(".calculator-1-container-js"); ////// variables of user inputs

var euroTrackUserData; ///unload country

var checkUnloadCountryBtn = document.querySelector(".check-unload-country-btn-js");
var dropdownUnloadCountry = document.querySelector(".dropdown-unload-country-js"); ///unloadplace

var checkUnloadCountryPlaceBtn = document.querySelector(".check-unload-country-place-btn-js");
var dropdownUnloadCountryPlace = document.querySelector(".dropdown-unload-country-place-js");
var chekcLoadCountryBtn = document.querySelector(".check-load-country-btn-js");
var dropdownLoadCountry = document.querySelector(".dropdown-load-country-js"); /// load place

var checkLoadCountryPlaceBtn = document.querySelector(".check-load-country-place-btn-js");
var dropdownLoadCountryPlace = document.querySelector(".dropdown-load-country-place-js"); ////////variables for gropcargo results

var formTypeCheckboxes = document.querySelector(".form-type-checkboxes-js");
var formTypeCheckboxesInputs;

if (formTypeCheckboxes) {
  formTypeCheckboxesInputs = formTypeCheckboxes.querySelectorAll("input");
}

if (calculatorContainer) {
  // seventhSupportFormSubmitBtnCalculatorModal.addEventListener('click',()=>{
  //   // event.preventDefault();
  //   // modalCalculator.style.display = 'none';
  // })
  var calculatoPageOnLoad = function calculatoPageOnLoad() {
    // contentTruck.classList.remove("visually-hidden");
    contentTruck.style = 'display: block'; // contentCargoWrapper.classList.remove("visually-hidden");

    creatFullTrackData(); // creatGroupCargoContent();

    typeOfTransportationBtn.addEventListener("click", function () {
      var target = event.target;
      var targerList = target.nextElementSibling.childNodes;
      targerList.forEach(function (targetEl) {
        targetEl.addEventListener("click", function () {
          if (targetEl.id == "fullTrack") {
            // contentCargoWrapper.classList.add("visually-hidden");
            // contentTruck.classList.remove("visually-hidden");
            contentCargoWrapper.style = "display: none";
            contentTruck.style = 'display: block';
            creatFullTrackData();
            return;
          }

          if (targetEl.id == "groupageСargo") {
            // contentTruck.classList.add("visually-hidden");
            // contentCargoWrapper.classList.remove("visually-hidden");
            // calculatorFormTruck.classList.add("visually-hidden");
            // caculatorDeliveryRequest.classList.add("visually-hidden");
            ///////////////////////////////////////////////////////////////////////////
            contentCargoWrapper.style = "display: block";
            contentTruck.style = 'display: none';
            calculatorFormTruck.style = "display: none";
            caculatorDeliveryRequest.style = "display: none";
            creatGroupCargoContent();
          }
        });
      });
    });
  };

  var getJsonData = function getJsonData(path) {
    return fetch(path).then(function (response) {
      if (response.ok) return response.json();
      throw new Error("Error fetching data");
    }).catch(function (error) {
      console.error("Error: ", error);
    });
  };

  var creatGroupCargoContent = function creatGroupCargoContent() {
    typeOfTransportationBtn.textContent = typesOfTransportation[Object.keys(typesOfTransportation)[1]];
    formTypeCheckboxesInputs[0].setAttribute("checked", "checked");
    getJsonData(groupCargoDataPath).then(updateVievGroupeCargo);
  };

  var updateVievGroupeCargo = function updateVievGroupeCargo(data) {
    cargoFullData = data;
    creatCurrentGropeCargoData(cargoFullData, "toDoorEurope");
  };

  var creatCurrentGropeCargoData = function creatCurrentGropeCargoData(currentObj, id) {
    var currentObjectDataArray = currentObj.find(function (el) {
      return el[id] !== undefined;
    });
    formTypeSumNumberCurrency.textContent = currentObjectDataArray["currencySymbol"];
    formTypeSumCounterValueLetter.textContent = currentObjectDataArray["volMes"];
    checkFromCommonContainerContry.textContent = currentObjectDataArray[id][0]["From"];
    currentObjectDataArray[id].map(function (el) {
      dropdownList += "<li class=\"dropdown-list-item dropdown-list-item--calculator-droupe-cargo dropdown-list-item--calculator-item\" id=\"".concat(el["From"], "\">").concat(el["From"], "</li>");
    });
    dropdownGropeCargoList.innerHTML = dropdownList;
    dropdownList = "";
    makeDropDownScroll();
    currentSubArray = currentObjectDataArray[id]["0"];
    formTypeSumNumberCurrent.textContent = currentSubArray["1"];
    checkFromCommonContainerContry.addEventListener("click", function () {
      var target = event.target;
      var targerList = target.nextElementSibling.childNodes;
      targerList.forEach(function (targetEl) {
        counter = 1;
        targetEl.addEventListener("click", function () {
          checkFromCommonContainerContry.textContent = targetEl.textContent;
          currentSubArray = currentObjectDataArray[id].find(function (el) {
            return el["From"] == targetEl.textContent;
          });
          formTypeSumNumberCurrent.textContent = currentSubArray[counter];
          formTypeSumCounterValueNumber.textContent = counter;
          return;
        });
      });
    });
    chooseCurrentFormNote(id);
  };

  var chooseCurrentFormNote = function chooseCurrentFormNote(id) {
    formTypeNotes.forEach(function (form) {
      form.classList.add("visually-hidden");

      if (form.id == id) {
        form.classList.remove("visually-hidden");
      }
    });
  };

  var chengeCurrentSumNumber = function chengeCurrentSumNumber(event, num) {
    // console.log(currentSubArray);
    counter = counter + num;
    var curentLength = Object.keys(currentSubArray).length - 1;

    if (counter < 0) {
      counter = 1;
      return;
    }

    if (counter > curentLength) {
      counter = counter - 1;
      return;
    }

    formTypeSumNumberCurrent.textContent = deleteComsOnstring(currentSubArray[counter]);
    formTypeSumCounterValueNumber.textContent = counter;
    return;
  };

  var groupeCargoChecboxHendler = function groupeCargoChecboxHendler() {
    event.stopImmediatePropagation();
    var target = event.target;
    groupCargoInputs.forEach(function (el) {
      el.removeAttribute('checked', 'checked');
    });
    target.setAttribute('checked', 'checked');
    console.log(target);

    if (target.id == "toDoorChinaGeneral") {
      chinaButtons.classList.remove("visually-hidden");
      chinaButtonsInputs.forEach(function (el) {
        el.parentNode.classList.remove('color-orange');
        el.nextElementSibling.style = "color: #ffffff"; // console.log(el);
        // el.removeAttribute('checked','checked');
        // console.log(el.nextElementSibling);
      });
      resetCurrentVal();
      creatCurrentGropeCargoData(cargoFullData, chinaButtonsInputs[0].id);
      chinaButtonsInputs[0].parentNode.classList.add('color-orange');
      chinaButtonsInputs[0].nextElementSibling.style = "background-color: #fe5000"; // console.log(chinaButtonsInputs[0].parentNode.children[1]);

      chinaButtonsInputs.forEach(function (el) {
        el.addEventListener("click", chinaButtonsCheckBoxHndler);
      });
      return;
    }

    chinaButtons.classList.add("visually-hidden");
    chinaButtonsInputs.forEach(function (el) {
      el.parentNode.classList.remove('color-orange'); // el.parentNode.children[1].removeAttribute('checked','checked');
    });
    resetCurrentVal();
    creatCurrentGropeCargoData(cargoFullData, target.id);
  };

  var chinaButtonsCheckBoxHndler = function chinaButtonsCheckBoxHndler() {
    event.stopImmediatePropagation();
    var target = event.target;
    console.log(chinaButtonsInputs);
    chinaButtonsInputs.forEach(function (el) {
      console.log(el);
      el.parentNode.classList.remove('color-orange');
      el.nextElementSibling.style = "background-color: #fffff";
    });
    target.parentNode.classList.add('color-orange');
    target.nextElementSibling.style = "background-color: #fe5000";
    resetCurrentVal();
    creatCurrentGropeCargoData(cargoFullData, target.id);
  };

  var resetCurrentVal = function resetCurrentVal() {
    currentSubArray = "";
    counter = 1;
    formTypeSumCounterValueNumber.textContent = counter;
  };

  var creatFullTrackData = function creatFullTrackData() {
    typeOfTransportationBtn.textContent = typesOfTransportation[Object.keys(typesOfTransportation)[0]];

    for (var key in typesOfTransportation) {
      dropdownList += "<li class=\"type-of-transportation-dropdown-item dropdown-list-item dropdown-list-item--calculator-item\" id=\"".concat(key, "\">").concat(typesOfTransportation[key], "</li>");
    }

    typeOfTransportationDropdownList.innerHTML = dropdownList;
    dropdownList = "";
    getJsonData(fulltrackPath).then(updateViewFulltrack);

    function updateViewFulltrack(data) {
      fullTruckData = data; // console.log(fullTruckData);

      fullTruckData["Load"].map(function (el) {
        if (!loadCountriesArr.includes(el["Conutry-name-rus"])) {
          loadCountriesArr.push(el["Conutry-name-rus"]);
        }
      });
      unloadCountriesArr = [];
      fullTruckData["unload"].map(function (el) {
        unloadCountriesArr.push(el["Conutry-name"]);
      }); // console.log(fullTruckData['unload'])
      // console.log(unloadCountriesArr);

      creatCountriesList(loadCountriesArr, dropdownLoadCountry);
      chekcLoadCountryBtn.textContent = loadCountriesArr[0];
      createLoadCitiesList(loadCountriesArr[0]);
      creatCountriesList(unloadCountriesArr, dropdownUnloadCountry);
      checkUnloadCountryBtn.textContent = unloadCountriesArr[0];
      createUnLoadCitiesList(unloadCountriesArr[0]);
    }

    function creatCountriesList(dataArr, path) {
      dataArr.map(function (el) {
        dropdownList += "<li class=\"dropdown-load-country dropdown-list-item\" id=\"".concat(el, "\">").concat(el, "</li>");
      });
      path.innerHTML = dropdownList;
      dropdownList = "";
      makeDropDownScroll();
    }

    function createLoadCitiesList(countryName) {
      fullTruckData["Load"].filter(function (el) {
        return el["Conutry-name-rus"] == countryName;
      }).map(function (el) {
        dropdownList += "<li class=\"dropdown-load-country-place-item dropdown-list-item dropdown-list-item--calculator-item\" id=\"".concat(el["City-name-rus"], "\">").concat(el["City-name-rus"], "</li>");
      });
      dropdownLoadCountryPlace.innerHTML = dropdownList;
      LoadCityId = dropdownLoadCountryPlace.firstElementChild.id;
      checkLoadCountryPlaceBtn.textContent = LoadCityId;
      dropdownList = "";
      makeDropDownScroll();
    }

    function createUnLoadCitiesList(counntryName) {
      var currentCountry = fullTruckData["unload"].find(function (el) {
        return el["Conutry-name"] == counntryName;
      });
      currentCountry["Cities"].map(function (el) {
        dropdownList += "<li class=\"dropdown-load-country-place-item dropdown-list-item dropdown-list-item--calculator-item\" id=\"".concat(el, "\">").concat(el, "</li>");
      });
      dropdownUnloadCountryPlace.innerHTML = dropdownList;
      UnLoadCityId = dropdownUnloadCountryPlace.firstElementChild.id;
      checkUnloadCountryPlaceBtn.textContent = UnLoadCityId;
      dropdownList = "";
      makeDropDownScroll();
    } ////made common functions bind arguments
    ///// choose current load contry


    chekcLoadCountryBtn.addEventListener("click", function () {
      var target = event.target;
      var targerList = target.nextElementSibling.childNodes;
      targerList.forEach(function (targetEl) {
        targetEl.addEventListener("click", function () {
          chekcLoadCountryBtn.textContent = targetEl.textContent;
          createLoadCitiesList(targetEl.id);
        });
      });
      makeDropDownScroll();
    }); ////// choose current city on load country

    checkLoadCountryPlaceBtn.addEventListener("click", function () {
      var target = event.target;
      var targerList = target.nextElementSibling.childNodes;
      targerList.forEach(function (targetEl) {
        targetEl.addEventListener("click", function () {
          checkLoadCountryPlaceBtn.textContent = targetEl.textContent;
        });
      });
      makeDropDownScroll();
    }); ///// choose current unload contry

    checkUnloadCountryBtn.addEventListener("click", function () {
      var target = event.target;
      var targerList = target.nextElementSibling.childNodes;
      targerList.forEach(function (targetEl) {
        targetEl.addEventListener("click", function () {
          checkUnloadCountryBtn.textContent = targetEl.textContent;
          createUnLoadCitiesList(targetEl.id);
        });
      });
      makeDropDownScroll();
    });
    checkUnloadCountryPlaceBtn.addEventListener("click", function () {
      var target = event.target;
      var targerList = target.nextElementSibling.childNodes;
      targerList.forEach(function (targetEl) {
        targetEl.addEventListener("click", function () {
          checkUnloadCountryPlaceBtn.textContent = targetEl.textContent;
        });
      });
      makeDropDownScroll();
    });
  }; ///// make dropdown scroll


  var makeDropDownScroll = function makeDropDownScroll() {
    dropdownLists.forEach(function (el) {
      el.style.height = "auto";
      el.style.overflowY = "visible";

      if (el.children.length >= 10) {
        el.style.height = "410px";
        el.style.overflowY = "scroll";
      }
    });
  }; /////creat first calc results


  var creatResultsForFullTruck = function creatResultsForFullTruck() {
    // event.preventDefault();
    // calculatorFormTruck.classList.remove("visually-hidden");
    calculatorFormTruck.style = "display: block"; // console.log(window.scrollY);
    // console.log(calculatorFormTruck.pageY);
    // window.scrollTo(0,2000)

    var calcDirectDays = calculatorFormTruck.querySelector(".calc-direct-days-js");
    var resultPriceDirect = calculatorFormTruck.querySelector(".result-price-direct-js");
    var calcCrossdocDays = calculatorFormTruck.querySelector(".calc-crossdoc-days-js");
    var resultPriceCrossdoc = calculatorFormTruck.querySelector(".result-price-crossdoc-js");
    var directVol = document.querySelector(".direct-vol-js");
    var crossdocVol = document.querySelector(".crossdoc-vol-js");
    var crossDoccResultPrice = findResult(chekcLoadCountryBtn.textContent, checkLoadCountryPlaceBtn.textContent, checkUnloadCountryPlaceBtn.textContent, fullTruckData["crossdoc"]);
    var crossDoccResultWeight = findResult(chekcLoadCountryBtn.textContent, checkLoadCountryPlaceBtn.textContent, "Truck", fullTruckData["crossdoc"]);
    var directResultPrice = findResult(chekcLoadCountryBtn.textContent, checkLoadCountryPlaceBtn.textContent, checkUnloadCountryPlaceBtn.textContent, fullTruckData["direct"]);
    var directResulWeight = findResult(chekcLoadCountryBtn.textContent, checkLoadCountryPlaceBtn.textContent, "Truck", fullTruckData["direct"]);
    var commonDeadlineResult = findResult(chekcLoadCountryBtn.textContent, checkLoadCountryPlaceBtn.textContent, checkUnloadCountryPlaceBtn.textContent, fullTruckData["dates"]);
    resultPriceDirect.textContent = deleteComsOnstring(directResultPrice);
    resultPriceCrossdoc.textContent = deleteComsOnstring(crossDoccResultPrice);
    directVol.textContent = directResulWeight;
    crossdocVol.textContent = crossDoccResultWeight;
    calcDirectDays.textContent = commonDeadlineResult.substr(0, commonDeadlineResult.length - 4);
    calcCrossdocDays.textContent = commonDeadlineResult.substr(0, commonDeadlineResult.length - 4);
  };

  var findResult = function findResult(loadCountry, loadCity, unloadCity, path) {
    var loadPlace;
    return loadPlace = path.filter(function (el) {
      return el["Conutry-name-rus"] == loadCountry;
    }).find(function (el) {
      return el["City-name-rus"] == loadCity;
    })[unloadCity];
  };

  var deleteComsOnstring = function deleteComsOnstring(str) {
    if (str.includes(",")) {
      return deleteSeparetor(str, ",");
    }

    if (str.includes(".")) {
      return deleteSeparetor(str, ".");
    }

    return str;

    function deleteSeparetor(str, currentSymbol) {
      var res = Array.from(str);
      res.splice(1, res.indexOf("".concat(currentSymbol)));
      return res.join("");
    }
  }; /////listeners for btns


  // modalCalculator.classList.remove('visually-hidden');
  var modalCalculatorHeadler = function modalCalculatorHeadler() {
    var pageOfset;
    seventhSupporFormSubmitCalculatorBtn.addEventListener("click", function () {
      event.stopImmediatePropagation();
      modalCalculator.classList.remove("visually-hidden"); // modalCalculator.style.display = 'block';

      if (window.innerWidth <= 480) {
        pageOfset = window.scrollY; // document.querySelector('html').classList.add('stop-scroll');
        // document.querySelector('body').classList.add('stop-scroll');

        document.querySelector('.header-wrapper-js').style.display = ' none';
        document.querySelector('.footer-wrapper').style.display = 'none';
        document.querySelector('main').style.display = 'none';
        document.querySelector('.modal').style.background = 'transparent';
      }
    });
    modalClose.addEventListener("click", function () {
      modalCalculator.classList.add("visually-hidden"); // modalCalculator.style.display = 'none';

      var submitApplicationModalCalculatorForm = document.querySelector('.submit-application-modal-calculator-form-js');
      var submitApplicationModalCalculatorFormInputs = submitApplicationModalCalculatorForm.querySelectorAll('input');
      var submitApplicationModalCalculatorFormTextAreas = submitApplicationModalCalculatorForm.querySelector('textarea');
      var userFileOnlineRequestDescr = submitApplicationModalCalculatorForm.querySelector('.user-file-online-request-descr-js');
      submitApplicationModalCalculatorFormInputs.forEach(function (item) {
        item.value = '';
      });
      submitApplicationModalCalculatorFormTextAreas.value = '';
      userFileOnlineRequestDescr.textContent = 'Прикрепить файл';

      if (window.innerWidth <= 480) {
        // document.querySelector('html').classList.remove('stop-scroll');
        // document.querySelector('body').classList.remove('stop-scroll');
        document.querySelector('.header-wrapper-js').style.display = ' block';
        document.querySelector('.footer-wrapper').style.display = 'block';
        document.querySelector('main').style.display = 'block';
        document.querySelector('.modal').style.background = 'rgba(1, 33, 105, 0.8)';
        window.scrollTo(0, pageOfset);
      }
    });
  };

  var typesOfTransportation = {
    fullTrack: "АВТО,  Full truck Load (FTL)",
    groupageСargo: "Cборные грузы"
  }; ///// vars for dynamyc content:
  ////all buttons
  ////types of Transportation

  var typeOfTransportationBtn = document.querySelector(".type-of-transportation-btn-js");
  var typeOfTransportationDropdownList = document.querySelector(".type-of-transportation-dropdown-list-js"); /////wrappers

  var contentCargoWrapper = document.querySelector(".content-cargo-wrapper-js");
  var contentTruck = document.querySelector(".content-truck-js"); ////all dropdown lists

  var dropdownLists = document.querySelectorAll(".dropdown-list-js"); /// load country

  var dropdownList = "";
  var LoadCountryId = "";
  var LoadCityId = "";
  var UnLoadCountryId = "";
  var UnLoadCityId = "";
  var fullTruckData;
  var loadCountriesArr = [];
  var unloadCountriesArr = []; //// button for calcute results for "full truck" type of cargo

  var formTypeCalculateBtn = document.querySelector(".form-type-calculate-btn-js"); //// table results

  var calculatorFormTruck = document.querySelector(".calculator-form-truck-js"); ////////variables for gropcargo results
  // const formTypeCheckboxes = document.querySelector(".form-type-checkboxes-js");
  // const formTypeCheckboxesInputs = formTypeCheckboxes.querySelectorAll("input");

  var cargoFullData;
  var checkFromCommonContainerContry = document.querySelector(".check-from-common-container-contry-js");
  var dropdownGropeCargoList = document.querySelector(".dropdown-grope-cargo-list-js");
  var formTypeSumNumberCurrent = document.querySelector(".form-type-sum-number-current-js");
  var groupCargoInputs = document.querySelectorAll(".group-cargo-input-js");
  var formTypeSumCounterValueNumber = document.querySelector(".form-type-sum-counter-value-number-js");
  var formTypeSumNumberCurrency = document.querySelector(".form-type-sum-number-currency-js");
  var formTypeSumCounterValueLetter = document.querySelector(".form-type-sum-counter-value-letter-js");
  var currentSubArray;
  var counter = 1; /////variable for cargo(china) results

  var chinaButtons = document.querySelector(".china-buttons-js"); ////form types

  var formTypeNotes = document.querySelectorAll(".form-type-note-js");
  var chinaButtonsInputs = document.querySelectorAll(".china-buttons-input-js"); /////buttons

  var seventhSupportFormSubmitFormTruckBtns = document.querySelectorAll(".seventh-support-form-submit-form-truck-js"); ////modals

  var caculatorDeliveryRequest = document.querySelector(".calculator-delivery-request-js");
  var seventhSupporFormSubmitCalculatorBtn = document.querySelector(".seventh-suppor-form-submit-calculator-btn-js");
  var modalCalculator = document.querySelector(".modal-calculator-js");
  var modalClose = document.querySelector(".modal-close-js");
  var seventhSupportFormSubmitBtnCalculatorModal = document.querySelector('.seventh-support-form-submit-btn-calculator-modal-js'); ///datas psth

  var fulltrackPath = "./json-data/fulltrackData.json";
  var groupCargoDataPath = "./json-data/groupCargoData.json";
  calculatoPageOnLoad();
  var buttonCounterMinus = document.querySelector(".button-counter-minus-js");
  var buttonCounterPlus = document.querySelector(".button-counter-plus-js");
  buttonCounterMinus.addEventListener("click", chengeCurrentSumNumber.bind(null, event, -1));
  buttonCounterPlus.addEventListener("click", chengeCurrentSumNumber.bind(null, event, 1));
  groupCargoInputs.forEach(function (el) {
    el.addEventListener("click", groupeCargoChecboxHendler);
  });
  formTypeCalculateBtn.addEventListener("click", creatResultsForFullTruck);
  seventhSupportFormSubmitFormTruckBtns.forEach(function (btn) {
    btn.addEventListener("click", function () {
      caculatorDeliveryRequest.style = "display: block";

      if (window.innerWidth <= 375) {
        caculatorDeliveryRequest.style.width = '100%';
      }

      if (btn.classList.contains('direct-car-js')) {
        var calculatorResultTableTop = document.querySelector('.calculator-result-table-top-js');
        getInputForEurotruck(calculatorResultTableTop);
      }

      if (btn.classList.contains('with-cross-docking-js')) {
        var calculatorResultTableBottom = document.querySelector('.calculator-result-table-bottom-js');
        getInputForEurotruck(calculatorResultTableBottom);
      }

      function getInputForEurotruck(currentEuroTruckData) {
        var calculatorResultTableAboutText = currentEuroTruckData.querySelector('.calculator-result-table-about-text-js');
        var calculatorResulttableprice = currentEuroTruckData.querySelector('.calculator-result-table-price-js');
        euroTrackUserData = "".concat(calculatorResultTableAboutText.textContent, ", ").concat(calculatorResulttableprice.textContent);
        console.log(euroTrackUserData);
      }
    });
  });
  modalCalculatorHeadler();
} ////////////////////////////////////////////////////////////////////////Mobile  ///////////////////////////////////


var internationalTransportationDescriptionTitleCalculator = document.querySelector('.international-transportation-description-title-calculator-js');
var decorationRectangleCalculator = document.querySelector('.decoration-rectangle-calculator-js');
var internationalTransportationDescriptionTextCalculator = document.querySelector('.international-transportation-description-text-calculator-js');
var infoCircleCalculatorTop = document.querySelector('.info-circle--calculator-top');
var internationalTransportationDescriptionWrapperCalculator = document.querySelector('.international-transportation-description-wrapper-calculator-js');

if (internationalTransportationDescriptionTitleCalculator) {
  var showOrHideTitleOnCalculator = function showOrHideTitleOnCalculator(width) {
    if (width <= 375) {
      internationalTransportationDescriptionTitleCalculator.classList.add('visually-hidden');
      decorationRectangleCalculator.classList.add('visually-hidden');
      internationalTransportationDescriptionTextCalculator.classList.add('visually-hidden');
      infoCircleCalculatorTop.classList.remove('visually-hidden');
    } else {
      internationalTransportationDescriptionTitleCalculator.classList.remove('visually-hidden');
      decorationRectangleCalculator.classList.remove('visually-hidden');
      internationalTransportationDescriptionTextCalculator.classList.remove('visually-hidden');
      infoCircleCalculatorTop.classList.add('visually-hidden');
    }
  };

  ;
  window.addEventListener('resize', function () {
    showOrHideTitleOnCalculator(window.innerWidth);
  });
  showOrHideTitleOnCalculator(window.innerWidth);
}

if (infoCircleCalculatorTop) {
  infoCircleCalculatorTop.addEventListener('click', function () {
    if (internationalTransportationDescriptionTextCalculator.classList.contains('visually-hidden')) {
      internationalTransportationDescriptionTextCalculator.classList.remove('visually-hidden');
      internationalTransportationDescriptionWrapperCalculator.classList.add('buttom-layer');
      internationalTransportationDescriptionWrapperCalculator.addEventListener('click', function () {
        internationalTransportationDescriptionTextCalculator.classList.add('visually-hidden');
        internationalTransportationDescriptionWrapperCalculator.classList.remove('buttom-layer');
      });
    } else {
      internationalTransportationDescriptionTextCalculator.classList.add('visually-hidden');
      internationalTransportationDescriptionWrapperCalculator.classList.remove('buttom-layer');
    }
  });
} //////////////////////////////sending letters functions 
///////autoForm(1st letter)


function sendDataFromRequestTableCalculator() {
  var euroTruckDynamicData = document.getElementById('euro-truck-dynamic-data');
  var euroTruckDynamicPrice = document.getElementById('euro-truck-dynamic-price');
  var loadingCalendarDate = document.getElementById('loading-calendar-date');
  euroTruckDynamicPrice.value = " ".concat(euroTrackUserData);
  euroTruckDynamicData.value = "".concat(chekcLoadCountryBtn.textContent, ",").concat(checkLoadCountryPlaceBtn.textContent, " - ").concat(checkUnloadCountryBtn.textContent, ",").concat(checkUnloadCountryPlaceBtn.textContent);

  if (loadingCalendarDate.value == '') {
    loadingCalendarDate.value = "\u041D\u0435 \u0443\u043A\u0430\u0437\u0430\u043D\u0430";
  }

  var thankYouPopUpWrapperOnCalculator = document.querySelector('.thank-you-pop-up-wrapper-js ');
  thankYouPopUpWrapperOnCalculator.classList.add('thank-you-pop-up__wrapper--bind-to-modal-calc');
  showThankUpopUp('.support-form-delivery-request-js');
  return;
} ///////groupCargoForm(2nd) letter


function sendDataFromModalFormCalculator() {
  var locationDataFromCheckbox = document.getElementById('location-data-from-checkbox');
  var locationDataFromDropdown = document.getElementById('location-data-from-dropdown');
  var priceOfGroupCargo = document.getElementById('price-of-group-cargo');
  var eplAmount = document.getElementById('epl-amount');
  var checkedInput = Array.from(formTypeCheckboxesInputs).find(function (input) {
    return input.getAttribute('checked', 'checked');
  });
  var checkFromCommonContainerContry = document.querySelector('.check-from-common-container-contry-js');
  var chinaButtons = document.querySelector('.china-buttons-js');

  if (!chinaButtons.classList.contains('visually-hidden')) {
    locationDataFromCheckbox.value = checkedInput.previousElementSibling.textContent + ", ".concat(Array.from(chinaButtons.children).find(function (el) {
      return el.classList.contains('color-orange');
    }).textContent);
  } else {
    locationDataFromCheckbox.value = checkedInput.previousElementSibling.textContent;
  }

  locationDataFromDropdown.value = checkFromCommonContainerContry.textContent;
  priceOfGroupCargo.value = "".concat(document.querySelector('.form-type-sum-number-current-js').textContent, " ").concat(document.querySelector('.form-type-sum-number-currency-js').textContent);
  eplAmount.value = "".concat(document.querySelector('.form-type-sum-counter-value-number-js').textContent, " ").concat(document.querySelector('.form-type-sum-counter-value-letter-js').textContent);
  var thankYouPopUpWrapperOnCalculator = document.querySelector('.thank-you-pop-up-wrapper-js ');
  thankYouPopUpWrapperOnCalculator.classList.add('thank-you-pop-up__wrapper--bind-to-modal-calc');
  showThankUpopUp('.submit-application-modal-calculator-form-js');
  return;
}