var sliderImagesPath = "./json-data/header-slider.json";
var sliderContainer = document.getElementById("container-slider-image"); 
if (sliderContainer) {
  var getJsonDataForheaderSlider = function getJsonDataForheaderSlider(path) {
    return fetch(path).then(function (response) {
      if (response.ok) return response.json();
      throw new Error("Error fetching data");
    }).catch(function (error) {
      console.error("Error: ", error);
    });
  };

  var makeHeaderSlider = function makeHeaderSlider(data) {
    sliderImagesLinks = data;
    var firstSliderControlLeft = document.getElementById("firstSliderControlLeft");
    var firstSliderControlRight = document.getElementById("firstSliderControlRight");
    var n = 0;
    showCurrentSlider(n);

    function showCurrentSlider(n) {
      sliderContainer.style.cssText = sliderImagesLinks[n].imageUrl;
      containerSliderImageDescText.textContent = sliderImagesLinks[n].imageDescrText;
    }

    var sliderInterval;

    if (window.innerWidth > 768) {
      sliderInterval = setInterval(sliderToTheRight, 4000);

      firstSliderControlLeft.onclick = function (e) {
        // console.log('click')
        clearInterval(sliderInterval);
        sliderToTheLeft();
        sliderInterval = setInterval(sliderToTheLeft, 4000);
      };

      firstSliderControlRight.onclick = function (e) {
        clearInterval(sliderInterval);
        sliderToTheRight();
        sliderInterval = setInterval(sliderToTheRight, 4000);
      };
    } else {
      sliderInterval = setInterval(sliderToTheRight, 4000);
    }

    function sliderToTheLeft() {
      n = n - 1;

      if (n < 0) {
        n = sliderImagesLinks.length - 1;
        showCurrentSlider(n);
      } else {
        showCurrentSlider(n);
      }
    }

    function sliderToTheRight() {
      n = n + 1;

      if (n > sliderImagesLinks.length - 1) {
        n = 0;
        showCurrentSlider(n);
        return;
      } else {
        showCurrentSlider(n);
        return;
      }
    }
  };

  var sliderImagesLinks;
  var containerSliderImageDescText = document.querySelector('.container-slider-image-descr-text-js');
  getJsonDataForheaderSlider(sliderImagesPath).then(makeHeaderSlider);
}

///////////////////////////////////////////////// v ver-----------------------------------------------------------------
// "use strict"
// var sliderImagesPath = "./json-data/header-slider.json";
////----------------------------------------------------------------------
// var sliderContainer = document.getElementById("container-slider-image"); 
// if (sliderContainer) {
//   // var getJsonDataForheaderSlider = function getJsonDataForheaderSlider(path) {
//   //   return fetch(path).then(function (response) {
//   //     if (response.ok) return response.json();
//   //     throw new Error("Error fetching data");
//   //   }).catch(function (error) {
//   //     console.error("Error: ", error);
//   //   });
//   // };

//   var makeHeaderSlider = function makeHeaderSlider() {
//     var sliderImagesLinks;
//     var containerSliderImageDescText = document.querySelector('.container-slider-image-descr-text-js');
//     var sliderImagesLinks = [{
//       "imageUrl": "background-image:url('./images/header_slider_photo.png')",
//       "imageDescrText": " Собственные сборные линии из 25 стран европы по заданному расписанию!"
//     }, {
//       "imageUrl": "background-image:url('./images/header_slider_photo_2.jpg')",
//       "imageDescrText": "Собственные сборные линии из 25 стран европы по заданному расписанию!"
//     }, {
//       "imageUrl": "background-image:url('./images/header_slider_photo_3.jpg')",
//       "imageDescrText": "Собственные сборные линии из 25 стран европы по заданному расписанию!"
//     }];
//     var firstSliderControlLeft = document.getElementById("firstSliderControlLeft");
//     var firstSliderControlRight = document.getElementById("firstSliderControlRight");
//     var n = 0;
//     showCurrentSlider(n);

//     function showCurrentSlider(n) {
//       sliderContainer.style.cssText = sliderImagesLinks[n].imageUrl;
//       containerSliderImageDescText.textContent = sliderImagesLinks[n].imageDescrText;
//     }

//     var sliderInterval;

//     if (window.innerWidth > 768) {
//       sliderInterval = setInterval(sliderToTheRight, 4000);

//       firstSliderControlLeft.onclick = function (e) {
//         // console.log('click')
//         clearInterval(sliderInterval);
//         sliderToTheLeft();
//         sliderInterval = setInterval(sliderToTheLeft, 4000);
//       };

//       firstSliderControlRight.onclick = function (e) {
//         clearInterval(sliderInterval);
//         sliderToTheRight();
//         sliderInterval = setInterval(sliderToTheRight, 4000);
//       };
//     } else {
//       sliderInterval = setInterval(sliderToTheRight, 4000);
//     }

//     function sliderToTheLeft() {
//       n = n - 1;

//       if (n < 0) {
//         n = sliderImagesLinks.length - 1;
//         showCurrentSlider(n);
//       } else {
//         showCurrentSlider(n);
//       }
//     }

//     function sliderToTheRight() {
//       n = n + 1;

//       if (n > sliderImagesLinks.length - 1) {
//         n = 0;
//         showCurrentSlider(n);
//         return;
//       } else {
//         showCurrentSlider(n);
//         return;
//       }
//     }
//   };

  
//   makeHeaderSlider();
//   // getJsonDataForheaderSlider(sliderImagesPath).then(makeHeaderSlider);
// }