"use strict";

var asideMenuItems = document.querySelectorAll('.international-transportation-details__item--calculator-1');
var asideMenuItemNoImage = document.querySelector('.international-transportation-details__item-no-image--calculator-1');


if (asideMenuItems && asideMenuItemNoImage) {
  var createLeftSideMenuOnLoad = function createLeftSideMenuOnLoad() {
    if (window.innerWidth < 769) {
      createLeftSideMenuForSmallScreen();
    } else {
      createLeftSideMenuForBigScreen();
    }
  };

  createLeftSideMenuOnLoad();
  window.addEventListener('resize', function () {
    if (window.innerWidth < 769) {
      createLeftSideMenuForSmallScreen();
    } else {
      createLeftSideMenuForBigScreen();
    }
  });
}

function createLeftSideMenuForSmallScreen() {
  asideMenuItemNoImage.classList.add('visually-hidden');
  asideMenuItems.forEach(function (element) {
    if (!element.firstElementChild.classList.contains('menu-svg-icon--active')) {
      element.classList.add('visually-hidden');
    } else {
      var notActiveSvgColor = "#0A2269";
      makeColorForCurrentSvg(element, notActiveSvgColor);
      element.classList.remove('hover-scale');
    }
  });
}

function createLeftSideMenuForBigScreen() {
  asideMenuItemNoImage.classList.remove('visually-hidden');
  asideMenuItems.forEach(function (element) {
    element.classList.remove('visually-hidden');

    if (element.firstElementChild.classList.contains('menu-svg-icon--active')) {
      var activeSvgColor = '#FE5000';
      makeColorForCurrentSvg(element, activeSvgColor);
      element.classList.add('hover-scale');
    }
  });
}

function makeColorForCurrentSvg(element, color) {
  element.firstElementChild.style.fill = color;
  element.firstElementChild.style.stroke = color;
  element.firstElementChild.nextElementSibling.style.color = color;
}