"use strict";

var mainWrapperCustomClearence = document.querySelector('.main-wrapper-custom-clearence-js');
var customClearenceTabSwitcher = document.querySelector('.custom-clearence-tab-switcher-js'); ////top doc slider

var internationalTransportationDocSlider = document.querySelector('.international-transportation-doc-slider-js');
var internationalTransportationDocSliderList = document.querySelector('.international-transportation-doc-slider-list-js');
var internationalTransportationDocSliderItems = document.querySelectorAll('.international-transportation-doc-slider-item-js'); ///// tabs custom clearence sheremetevo

var tabSwitcherLink = document.querySelectorAll('.tab-switcher-link-js');
var tabSwitcherResult = document.querySelectorAll('.tab-switcher-result-js'); /////tabs for customer case

var customerCaseTabSwircherLinks = document.querySelectorAll('.customer-case-tab-swircher-link-js');
var customerCaseResultsTable = document.querySelectorAll('.customer-case-results-table-js'); /////// nameplate slider

var nameplateSliderCustomClearence = document.querySelector('.nameplate-slider-custom-clearence-js');
var nameplateSliderListCustomClearence = document.querySelector('.nameplate-slider-list-custom-clearence-js');
var nameplateSliderItemsCustomClearence = document.querySelectorAll('.nameplate-slider-item-custom-clearence-js');

if (mainWrapperCustomClearence) {
  var createSwiperClassesForCustomClearence = function createSwiperClassesForCustomClearence(container, wrapper, itemsList) {
    container.classList.add('swiper-container');
    container.classList.add('swiper-container-customs');
    wrapper.classList.add('swiper-wrapper');
    itemsList.forEach(function (item) {
      item.classList.add('swiper-slide');
    });
  };

  createSwiperClassesForCustomClearence(internationalTransportationDocSlider, internationalTransportationDocSliderList, internationalTransportationDocSliderItems);
  createSwiperClassesForCustomClearence(nameplateSliderCustomClearence, nameplateSliderListCustomClearence, nameplateSliderItemsCustomClearence);
  var docSliderSwiper = new Swiper('.international-transportation-doc-slider-js', {
    allowTouchMove: false,
    pagination: {
      el: '.swiper-pagination' // type: 'bullets',

    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      480: {
        allowTouchMove: true
      }
    }
  });
  var nameplateSliderCustomClearenceSwiper = new Swiper('.nameplate-slider-custom-clearence-js', {
    allowTouchMove: false,
    pagination: {
      el: '.swiper-pagination' // type: 'bullets',

    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      480: {
        allowTouchMove: true
      }
    }
  }); ////////////////switcher  for Sheremetevo table
}

if (tabSwitcherLink) {
  tabSwitcherForTables(tabSwitcherLink, tabSwitcherResult);
}

if (customerCaseTabSwircherLinks) {
  tabSwitcherForTables(customerCaseTabSwircherLinks, customerCaseResultsTable);
}

function tabSwitcherForTables(links, results) {
  links.forEach(function (link) {
    link.addEventListener('click', function () {
      links.forEach(function (element) {
        element.classList.remove('link--active-border');
      });
      link.classList.add('link--active-border');
      var currentLinkId = link.getAttribute('id').slice(2);
      results.forEach(function (element) {
        element.classList.remove('results--active');
      });
      document.getElementById(currentLinkId).classList.add('results--active');
    });
  });
}