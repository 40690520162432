"use strict";

var headerWrapper = document.querySelector(".header-wrapper-js");
var internationalTransportationOfferToSenders = document.querySelector('.international-transportation-offer-to-senders-js'); /////headers

var headersTpl = document.querySelectorAll('.header-tpl-js');
var bigHeaderTpl = document.querySelector('.big-header-tpl-js');
var smallHeaderTpl = document.querySelector('.small-header-tpl-js');
var currentInnerWidth = window.innerWidth;

function onloadHeader() {
  makeCurrentHeaderTpl();
}

onloadHeader();

function makeCurrentHeaderTpl() {
  if (window.innerWidth > 768) {
    bigHeaderTpl.classList.remove('visually-hidden');
    smallHeaderTpl.classList.add('visually-hidden');
    makeStickeButtons();

    if (internationalTransportationOfferToSenders) {
      internationalTransportationOfferToSenders.classList.remove('visually-hidden');
    }
  } else {
    bigHeaderTpl.classList.add('visually-hidden');
    smallHeaderTpl.classList.remove('visually-hidden');
    makeStickeButtons();
    burgerHendler();

    if (internationalTransportationOfferToSenders) {
      internationalTransportationOfferToSenders.classList.add('visually-hidden');
    }
  }
}

window.addEventListener("resize", function () {
  if (window.innerWidth !== currentInnerWidth) {
    currentInnerWidth = window.innerWidth;
    makeCurrentHeaderTpl();
  }
});

function burgerHendler() {
  var headerHideMenu = document.querySelector(".header-hide-menu-js");
  var burgerLinesList = document.querySelector(".burger-lines-list-js");
  var closeHideMenuBtn = document.querySelector(".close-hide-menu-btn-js");
  var transportirBottomNavSublist = document.querySelectorAll(".transportir-bottom-nav-sublist-js");
  var mainWrapper = document.querySelector('.main-wrapper');
  var footerWrapper = document.querySelector('.footer-wrapper');
  var pageOfset;
  closeHideMenuBtn.style.display = 'none';
  burgerLinesList.addEventListener("click", function () {
    headerHideMenu.style.right = "0px";
    closeHideMenuBtn.style.display = 'block';
    pageOfset = window.scrollY;
    document.querySelector('html').classList.add('stop-scroll');
    document.querySelector('body').classList.add('stop-scroll');
    setTimeout(function () {
      mainWrapper.addEventListener('click', onMenuClick);
    }, 500);
    setTimeout(function () {
      footerWrapper.addEventListener('click', onMenuClick);
    }, 500);

    function onMenuClick() {
      headerHideMenu.style.right = "1000px";
    }
  });
  closeHideMenuBtn.addEventListener("click", function () {
    headerHideMenu.style.right = "1000px";
    closeHideMenuBtn.style.display = 'none';
    document.querySelector('html').classList.remove('stop-scroll');
    document.querySelector('body').classList.remove('stop-scroll');
    window.scrollTo(0, pageOfset);
  });

  function headerOnSmallscreen() {
    transportirBottomNavSublist.forEach(function (item) {
      item.previousElementSibling.classList.add('plus-btn');
      item.parentElement.classList.add("item-menu-submenu-js");
    });
    var itemsMenuSubmenu = document.querySelectorAll(".item-menu-submenu-js");
    var plusButtons = document.querySelectorAll('.transportir-bottom-nav-opener-btn-js');
    plusButtons.forEach(function (item) {
      item.addEventListener('click', function () {
        var currentSubItem = item.nextElementSibling;

        if (!currentSubItem.classList.contains('visually-hidden')) {
          currentSubItem.classList.add('visually-hidden');
          item.classList.add('plus-btn');
          return;
        }

        currentSubItem.classList.remove('visually-hidden');
        item.classList.add('minus-btn');
        item.classList.remove('plus-btn');
      });
    });
    var navItems = document.querySelectorAll('.nav-item-js');
    navItems.forEach(function (item) {
      item.addEventListener('click', function () {
        var navLink = item.querySelector('.nav-link-js');
        var popularServivece = navLink.nextElementSibling;
        var dropdown = popularServivece.nextElementSibling;

        if (item.classList.contains('opend-nav-item')) {
          item.classList.remove('opend-nav-item');
          navLink.style.color = '#012169';
          popularServivece.classList.add('visually-hidden');
          dropdown.classList.add('visually-hidden');
          dropdown.style.display = 'none';
        } else {
          item.classList.add('opend-nav-item');
          navLink.style.color = '#FE5000';
          popularServivece.classList.remove('visually-hidden');
          dropdown.classList.remove('visually-hidden');
          dropdown.style.display = 'block';
        }
      });
    });
  }

  headerOnSmallscreen();
} ////////////////////////////scrollBehavior: 


function makeStickeButtons() {
  var buttonsIndexHtml = document.getElementById("buttons_index_html");
  var buttons = document.getElementById("buttons");
  var navbar = document.getElementById("navbar");
  var headerMobileWrapper = document.querySelector(".header-mobile-wrapper");

  if (navbar) {
    var sticky = navbar.offsetTop;

    if (headerMobileWrapper) {
      var sticky = headerMobileWrapper.offsetTop;
    }

    navbarScroll();
  }

  window.onscroll = function () {
    navbarScroll();
    buttonsScroll();
  };

  function navbarScroll() {
    if (!bigHeaderTpl.classList.contains('visually-hidden')) {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    } else {
      if (window.pageYOffset >= sticky) {
        headerMobileWrapper.classList.add("header-mobile-sticky");
      } else {
        headerMobileWrapper.classList.remove("header-mobile-sticky");
        headerMobileWrapper.nextElementSibling.style.top = '0px';
      }
    }
  }

  function buttonsScroll() {
    if (buttonsIndexHtml) {
      creatStikcyButtons(buttonsIndexHtml, 300, "stickyButtons");
    }

    if (buttons) {
      creatStikcyButtons(buttons, 130, "stickyButtons--secondScroll");
    }
  }

  function creatStikcyButtons(id, offsetValue, buttonsClassName) {
    if (window.pageYOffset >= offsetValue) {
      id.classList.add(buttonsClassName);
    } else {
      id.classList.remove(buttonsClassName);
    }
  }
} // function noScroll() {
//   window.scrollTo(0, 0);
// }