"use strict";

var groupCargoRequestBtn = document.querySelector('.group-cargo-request-btn-js');
var modalDeliveryScheduleVariantSvhTransportirInput = document.querySelector('.modal-delivery-schedule-variant-svh-transportir-input-js');

if (groupCargoRequestBtn) {
  var modalDeliveryScheduleRequestEurope = document.querySelector('.modal-delivery-schedule-request-europe-js');
  var modalDeliveryScheduleCloseBtnRequestEurope = document.querySelector('.modal-delivery-schedule-close-btn-request-europe-js');
  var modalDeliveryScheduleWrapperForShort = modalDeliveryScheduleRequestEurope.querySelector('.modal-delivery-schedule__wrapper');
  var currentWindowInnerHight = window.innerHeight;
  var currentPagePositionForModalonGrouoCargoFromEuropePage;
  groupCargoRequestBtn.addEventListener('click', function () {
    modalDeliveryScheduleRequestEurope.style.display = 'block';

    if (window.innerWidth > 480) {
      var modalHeight = modalDeliveryScheduleWrapperForShort.clientHeight;
      var navbarHeight = document.querySelector('#navbar').clientHeight;

      if (navbarHeight > 46) {
        modalDeliveryScheduleWrapperForShort.style.marginTop = '90px';
      } else {
        modalDeliveryScheduleWrapperForShort.style.marginTop = '40px';
      }

      if (modalHeight + navbarHeight + 40 > currentWindowInnerHight) {
        var modalWrapperCurrentHeight = currentWindowInnerHight - navbarHeight - 40;
        modalDeliveryScheduleWrapperForShort.style.overflowY = 'scroll';
        modalDeliveryScheduleWrapperForShort.style.height = "".concat(currentWindowInnerHight - navbarHeight - 60, "px");
      }
    } // console.log(modalDeliveryScheduleWrapper.clientHeight);


    if (window.innerWidth <= 480) {
      currentPagePositionForModalonGrouoCargoFromEuropePage = window.scrollY; // console.log(currentPagePositionForModalonGrouoCargoFromEuropePage);

      hideShowBlocksOnMobile('none');
      window.scrollTo(0, 0);
    }

    modalDeliveryScheduleVariantSvhTransportirInput.addEventListener('click', toggleModalRadioInput);
    modalDeliveryScheduleCloseBtnRequestEurope.addEventListener('click', function () {
      modalDeliveryScheduleRequestEurope.style.display = 'none';

      if (window.innerWidth <= 480) {
        hideShowBlocksOnMobile('block');
        window.scrollTo(0, currentPagePositionForModalonGrouoCargoFromEuropePage - 1200);
      }

      modalDeliveryScheduleVariantSvhTransportirInput.removeEventListener('click', toggleModalRadioInput);
      clearAllUsewrsDataAtmodalDeliveryScheduleRequestEurope();
    });

    function toggleModalRadioInput() {
      modalDeliveryScheduleVariantSvhTransportirInput.nextElementSibling.classList.toggle("delivery-modal-label--active");
    }

    function hideShowBlocksOnMobile(styleName) {
      document.querySelector('.header-wrapper-js').style.display = "".concat(styleName);
      document.querySelector('.container-slider-image-js').style.display = "".concat(styleName);
      document.querySelector('.main-wrapper-hide-for-modal-js').style.display = "".concat(styleName);
      document.querySelector('.footer-wrapper').style.display = "".concat(styleName);
    }

    function clearAllUsewrsDataAtmodalDeliveryScheduleRequestEurope() {
      var modalDeliveryScheduleRequestEuropeInputList = modalDeliveryScheduleRequestEurope.querySelectorAll('input');
      var modalDeliveryScheduleRequestEuropeTextAreaList = modalDeliveryScheduleRequestEurope.querySelectorAll('textarea');
      modalDeliveryScheduleVariantSvhTransportirInput.classList.remove("delivery-modal-label--active");
      clearInputModalValue(modalDeliveryScheduleRequestEuropeInputList);
      clearInputModalValue(modalDeliveryScheduleRequestEuropeTextAreaList);
    }

    function clearInputModalValue(inputArr) {
      inputArr.forEach(function (input) {
        input.value = '';
      });
    }
  });
} /////function for sending letters at the same page like delivey-schedule form at groupage cargo page


function sendDataFromDeliveryScheduleGroupageCargo() {
  var isSvhNeedInput = document.querySelector('.is-svh-need-js');
  var thankYouPopUpWrapper = document.querySelector('.thank-you-pop-up-wrapper-js');

  if (modalDeliveryScheduleVariantSvhTransportirInput.nextElementSibling.classList.contains("delivery-modal-label--active")) {
    isSvhNeedInput.value = 'Отмечено';
  } else {
    isSvhNeedInput.value = 'Не отмечено';
  }

  ;
  thankYouPopUpWrapper.classList.add('thank-you-pop-up__wrapper--bind-to-modal-calc');
  showThankUpopUp('.submit-application-delivery-schedule-groupage-cargo-slider-js');
  return;
}