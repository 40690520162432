"use strict";

var sliderReviewsContainer = document.querySelector('.slider-reviews-container-js');

if (sliderReviewsContainer) {
  var addClassForCurrentSliderElemet = function addClassForCurrentSliderElemet(elements, classElementName) {
    Array.from(elements).forEach(function (element) {
      element.classList.add(classElementName);
    });
  }; ////create sliders onj rewiew page


  ////onload hide all sliders and show 1st
  var hideAllRewiewSliders = function hideAllRewiewSliders() {
    sliderRewiewsCardsContainerOuterList.forEach(function (slider) {
      slider.style.display = 'none';
    });
  };

  // var for tab switcher
  var sliderRewiewsCardsContainerOuterList = document.querySelectorAll('.slider-rewiews-cards-container-outer-js');
  var sliderReviewsContentControlsLinkList = document.querySelectorAll('.slider-reviews-content-controls-link-js'); ////var for creating slider

  var sliderRewiewsCardsContainerList = document.querySelectorAll('.slider-rewiews-cards-container-js');
  var sliderRewiewsCardsWrapperList = document.querySelectorAll('.slider-rewiews-cards-wrapper-js');
  var sliderReviewsSlideList = document.querySelectorAll('.slider-reviews-slide-js');
  addClassForCurrentSliderElemet(sliderRewiewsCardsContainerList, 'swiper-container');
  addClassForCurrentSliderElemet(sliderRewiewsCardsWrapperList, 'swiper-wrapper');
  addClassForCurrentSliderElemet(sliderReviewsSlideList, 'swiper-slide');
  var ownersReviewsSliderSwiper = new Swiper('.owners-reviews-slider-container-js', {
    slidesPerView: 3,
    slidesPerColumn: 2,
    spaceBetween: 35,
    slidesPerGroup: 3,
    pagination: {},
    navigation: {
      nextEl: '.owners-reviews-slider-reviews-button-next',
      prevEl: '.owners-reviews-slider-reviews-button-prev'
    },
    breakpoints: {
      1136: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 10
      },
      768: {
        slidesPerView: 1,
        slidesPerColumn: 1,
        slidesPerGroup: 1,
        pagination: {
          el: '.slider-reviews-pagination',
          dynamicBullets: true
        }
      }
    }
  });
  var carriersReviewsSliderSwiper = new Swiper('.carriers-reviews-slider-container-js', {
    slidesPerView: 3,
    slidesPerColumn: 2,
    spaceBetween: 35,
    slidesPerGroup: 3,
    pagination: {},
    navigation: {
      nextEl: '.carriers-reviews-slider-reviews-button-next',
      prevEl: '.carriers-reviews-slider-reviews-button-prev'
    },
    breakpoints: {
      1136: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 10
      },
      768: {
        slidesPerView: 1,
        slidesPerColumn: 1,
        slidesPerGroup: 1,
        pagination: {
          el: '.slider-reviews-pagination',
          dynamicBullets: true
        }
      }
    }
  });;
  hideAllRewiewSliders();
  sliderRewiewsCardsContainerOuterList[0].style.display = 'block'; ///onload make active links

  sliderReviewsContentControlsLinkList[0].classList.add('link--active-border');
  sliderReviewsContentControlsLinkList.forEach(function (link) {
    link.addEventListener('click', function () {
      sliderReviewsContentControlsLinkList.forEach(function (currentLink) {
        currentLink.classList.remove('link--active-border');
      });
      link.classList.add('link--active-border');
      var currentId = link.getAttribute('id').slice(2);
      hideAllRewiewSliders();
      document.getElementById(currentId).style.display = 'block';
    });
  });
}