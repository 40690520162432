"use strict";

var submitApplicationDeliveryScheduleFastTrains = document.querySelector('.submit-application-delivery-schedule-fast-trains-js');

if (submitApplicationDeliveryScheduleFastTrains) {
  var modalDeliveryScheduleFastTrains = document.querySelector('.modal-delivery-schedule-fast-trains-js'); ///get all buttons on slider cards

  var trspThirdBlockCardFooterButtonList = document.querySelectorAll(".trsp-16-third-block-card-footer-button-js");
  var currentWayForInput;
  var currentPriceForInput;
  var currentDeadlineInput;
  trspThirdBlockCardFooterButtonList.forEach(function (link) {
    link.addEventListener('click', function () {
      // console.log(event);
      var pageOfsetFastTrains; ////reset input data

      currentWayForInput = '';
      currentPriceForInput = '';
      currentDeadlineInput = ''; ////get link parentNode

      var linkParentNode = link.parentElement.parentElement;
      var poinstOfWay = linkParentNode.querySelectorAll('.trsp-16-third-block__card__body-title-city');
      var thrueThepointWay = linkParentNode.querySelector('.trsp-16-third-block__card__body-title-subtitle-bigger');
      var currentCardPrice = linkParentNode.querySelector('.trsp-16-third-block__card__body-price-bigger');
      var currentBodyDetails = linkParentNode.querySelector('.trsp-16-third-block__card__body-details'); /////data from input for letter to transportir company

      currentWayForInput = "".concat(poinstOfWay[0].innerText, " - ").concat(poinstOfWay[1].innerText, " \u0447\u0435\u0440\u0435\u0437 ").concat(thrueThepointWay.innerText, " \u043E\u0442 ").concat(currentCardPrice.innerText, " \u0437\u0430 ").concat(currentBodyDetails.innerText);
      document.querySelector('.modal-delivery-schedul-input-fast-trains-card-slider-data').value = currentWayForInput; ////work with current modal

      modalDeliveryScheduleFastTrains.style.display = 'block';
      var modalDeliveryScheduleFastTrainsCloseBtn = modalDeliveryScheduleFastTrains.querySelector('.modal-delivery-schedule-close-btn-js');

      if (window.innerWidth <= 480) {
        pageOfsetFastTrains = event.pageY;
        document.querySelector('.header-wrapper-js').style.display = 'none';
        document.querySelector('.container-slider-wrapper').style.display = 'none';
        document.querySelector('.main-wrapper').style.display = 'none';
        document.querySelector('.footer-wrapper').style.display = 'none';
        window.scrollTo(0, 0);
      }

      modalDeliveryScheduleFastTrainsCloseBtn.addEventListener('click', function () {
        modalDeliveryScheduleFastTrains.style.display = 'none'; ///clear fields

        var modalDeliveryScheduleFastTrainsInputsList = modalDeliveryScheduleFastTrains.querySelectorAll('input');
        var modalDeliveryScheduleFastTrainstextArea = modalDeliveryScheduleFastTrains.querySelector('textarea');
        modalDeliveryScheduleFastTrainsInputsList.forEach(function (inputItem) {
          inputItem.value = '';
        });
        modalDeliveryScheduleFastTrainstextArea.value = '';

        if (window.innerWidth <= 480) {
          document.querySelector('.header-wrapper-js').style.display = 'block';
          document.querySelector('.container-slider-wrapper').style.display = 'block';
          document.querySelector('.main-wrapper').style.display = 'block';
          document.querySelector('.footer-wrapper').style.display = 'block';
          window.scrollTo(0, pageOfsetFastTrains - 550);
        }
      });
    });
  });
}